import { useMemo } from 'react';
import { Maybe } from 'yup';

import { getPaymentInfo } from './getPaymentInfo';

export const usePaymentInfo = (saleId: Maybe<string>) => {
  return useMemo(() => {
    return saleId ? getPaymentInfo(saleId) : undefined;
  }, [saleId]);
};
