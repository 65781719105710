import { localStorage } from 'utils/storage';

import { PAYMENT_STORE_KEY, paymentStoreSchema } from './paymentInfo';

export const getPaymentStore = () => {
  try {
    const json = JSON.parse(localStorage.getItem(PAYMENT_STORE_KEY) ?? '');
    return paymentStoreSchema.validateSync(json);
  } catch {
    return [];
  }
};
