import dynamic from 'next/dynamic';
import { useEffect } from 'react';

import { useIdentity } from 'hooks/useIdentity';
import { useIsConsumerUser } from 'hooks/useIsConsumerUser';
import { useStoredState } from 'hooks/useStoredState';

import { StorageKey } from 'types/stateStore';

const ProfileTooltip = dynamic(() => import('./ProfileTooltip'), { ssr: false });
export const ProfileTooltipWrapper = () => {
  const [identity] = useIdentity();
  const isConsumerRoleSelected = useIsConsumerUser();
  const [hasDismissedProfileTooltip, setHasDismissedProfileTooltip] = useStoredState(
    StorageKey.HasDismissedProfileTooltip,
  );
  const tooltipCondition =
    identity?.isCiamUser &&
    identity.isConsumer &&
    identity.isCorporate &&
    !hasDismissedProfileTooltip;

  useEffect(() => {
    if (tooltipCondition && isConsumerRoleSelected) {
      // If condition is met and user is already in personal profile, do not show tooltip later
      setHasDismissedProfileTooltip(true);
    }
  }, [isConsumerRoleSelected, setHasDismissedProfileTooltip, tooltipCondition]);

  if (!identity?.isAuthenticated) return null;

  return tooltipCondition && !isConsumerRoleSelected ? <ProfileTooltip /> : null;
};
