'use client';
import { useIntl } from 'react-intl';

import { Box, Text } from 'components/cssModules';
import LocalizedMessage from 'components/i18n/LocalizedMessage';
import Link from 'components/ui/Link';
import { Container } from 'components/ui/grid';

import { FeedbackMessage } from 'constants/i18n.messages';

import { padding } from 'styles/compLib';

import { useIsInPurchaseFlow } from 'hooks/purchaseFlow/useIsInPurchaseFlow';
import { useIsFormPage } from 'hooks/useIsFormPage';
import styles from './Feedback.module.css';

export const Feedback = () => {
  const { formatMessage } = useIntl();

  const inPurchaseFlow = useIsInPurchaseFlow();
  const isFormPage = useIsFormPage();

  const showFeedBack = !(isFormPage || inPurchaseFlow);

  if (!showFeedBack) {
    return null;
  }

  return (
    <Box as="div" color="grey10" className={padding.s32Y}>
      <Container className={styles.container}>
        <Box as="div" className={styles.content} spacing="s08Column">
          <Text title5 as="h2">
            <LocalizedMessage id={FeedbackMessage.TITLE} />
          </Text>
          <Text paragraph={16}>
            <LocalizedMessage id={FeedbackMessage.BODY} />
          </Text>
        </Box>

        <Link
          className="vr-button"
          data-canvas="blue20"
          id="feedback-button"
          href={formatMessage({ id: FeedbackMessage.BUTTON_LINK })}
        >
          <LocalizedMessage id={FeedbackMessage.BUTTON} />
        </Link>
      </Container>
    </Box>
  );
};
