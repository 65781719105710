import { getSessionId } from './getSessionId';
import { generateRandomId } from './misc';

export const fetcher = async (url: string) => {
  const response = await fetch(url, {
    headers: {
      ['x-vr-sessionid']: getSessionId(),
      ['x-vr-requestid']: generateRandomId(),
    },
  });

  if (!response.ok) {
    throw new Error(`Request failed: ${response.status} ${response.statusText}`);
  }

  return response.json();
};
