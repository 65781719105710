import classNames from 'classnames';
import { ReactNode } from 'react';

import { IconProps } from '@vrfi/web-components';

import { ProfilePages } from 'build/pages/types';

import { Box, Icon as IconC, Text } from 'components/cssModules';
import LocalizedMessage from 'components/i18n/LocalizedMessage';
import { ChevronRightIcon, TicketSingleIcon, UserDefaultIcon } from 'components/ui/Icons';
import Link from 'components/ui/Link';

import { ProfileSelectionMessage } from 'constants/i18n.messages';

import { useSiteData } from 'contexts/siteData';

import { usePageSlug, usePageTitle } from 'hooks/usePages';

import { margin } from 'styles/compLib';

import styles from './MenuLink.module.css';

const MenuLink = ({
  target,
  onClick,
  Icon,
  testId,
  children,
}: {
  target?: string;
  testId: string;
  Icon?: (props: IconProps) => ReturnType<typeof IconC>;
  onClick: () => void;
  children: ReactNode;
}) => (
  <Box
    as={Link}
    href={target ?? ''}
    onClick={onClick}
    className={classNames(styles.link, { [styles.withIcon]: !!Icon })}
    data-testid={testId}
  >
    <div>
      {Icon && <Icon color="var(--color-blue10)" className={margin.s16Right} />}

      <Text size={16} semibold blue10>
        {children}
      </Text>
    </div>
    <ChevronRightIcon size={2} color="var(--color-blue10)" />
  </Box>
);

export const JourneysLink = ({
  showIcon,
  onClick,
}: {
  showIcon?: boolean;
  onClick: () => void;
}) => {
  const journeysLinkTarget = usePageSlug(ProfilePages.MY_JOURNEYS_PAGE);

  return (
    <MenuLink
      target={journeysLinkTarget}
      testId="journeys-link"
      onClick={onClick}
      Icon={showIcon ? TicketSingleIcon : undefined}
    >
      <LocalizedMessage id={ProfileSelectionMessage.JOURNEYS_LINK_TEXT} />
    </MenuLink>
  );
};

export const AdminLink = ({ onClick }: { onClick: () => void }) => {
  const { b2bAdminPathnames } = useSiteData();

  return (
    <MenuLink target={b2bAdminPathnames.b2bAdminCompanyInfo} testId="admin-link" onClick={onClick}>
      <LocalizedMessage id={ProfileSelectionMessage.ADMIN_TOOL_LINK_TEXT} />
    </MenuLink>
  );
};

export const CodeGeneratorLink = ({ onClick }: { onClick: () => void }) => {
  const { b2bAdminPathnames } = useSiteData();

  return (
    <MenuLink
      target={b2bAdminPathnames.b2bAdminTravelCodes}
      testId="code-generator-link"
      onClick={onClick}
    >
      <LocalizedMessage id={ProfileSelectionMessage.CODE_GENERATOR_LINK_TEXT} />
    </MenuLink>
  );
};

export const ReportLink = ({ onClick }: { onClick: () => void }) => {
  const { b2bAdminPathnames } = useSiteData();

  return (
    <MenuLink target={b2bAdminPathnames.b2bAdminReport} testId="report-link" onClick={onClick}>
      <LocalizedMessage id={ProfileSelectionMessage.REPORT_LINK} />
    </MenuLink>
  );
};

export const ProfileLink = ({ onClick }: { onClick: () => void }) => {
  const profilePageTitle = usePageTitle(ProfilePages.PROFILE_PAGE);
  const profileLinkTarget = usePageSlug(ProfilePages.PROFILE_PAGE);

  return (
    <MenuLink
      Icon={UserDefaultIcon}
      target={profileLinkTarget}
      testId="profile-link"
      onClick={onClick}
    >
      {profilePageTitle}
    </MenuLink>
  );
};
