'use client';
import gql from 'graphql-tag';
import { useEffect } from 'react';

import { UserIdentityFragment } from 'backend/fragments/backend-fragments';
import { useLogoutV2 } from 'backend/hooks/useLogout';

import { useBackendClient } from 'contexts/backendClient';
import { mapUserFragmentToIdentity } from 'contexts/identity';
import { useSiteData } from 'contexts/siteData';

import { useSelectedRole } from 'hooks/useSelectedRole';
import { setStoredState } from 'hooks/useStoredState';

import { StorageKey } from 'types/stateStore';

/**
 * This is a own component rather a hook so it wouldn't cause rerenders to the parent component.
 * useSelectedRole might cause some renders to occur.
 */
export const TestTools = () => {
  const backendClient = useBackendClient();
  const { setSelectedRole } = useSelectedRole();
  const [logout] = useLogoutV2();
  const { landingPage } = useSiteData();

  useEffect(() => {
    window.testTools = {
      async login(username: string, password: string) {
        const { data } = await backendClient.mutate({
          variables: {
            username,
            password,
          },
          mutation: gql`
            mutation testLogin($username: String!, $password: String!) {
              testLogin(username: $username, password: $password) {
                user {
                  ...UserIdentity
                }
              }
            }
            ${UserIdentityFragment}
          `,
        });

        setStoredState(StorageKey.UserIdentity, mapUserFragmentToIdentity(data.testLogin.user));
      },
      async logout() {
        await logout({ variables: { redirectUrl: landingPage } });
      },
      async setSelectedRole(role) {
        await setSelectedRole(role);
      },
    };
  }, [backendClient, landingPage, logout, setSelectedRole]);

  return null;
};
