import { ReactNode, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { visuallyHidden } from '@vrfi/web-components/css/utils.module.css';

import { pushProfileSelectEvent } from 'analytics/userActions/pushProfileSelectEvent';

import { JwtCompanyRole } from 'backend/types.codegen';

import { Button } from '@vrfi/web-components';
import { Box, Line, Text } from 'components/cssModules';
import LocalizedMessage from 'components/i18n/LocalizedMessage';
import { ActionButton } from 'components/ui/ActionButton';
import { CancelIcon, InfoCircleLinesIcon } from 'components/ui/Icons/index';
import { Loading } from 'components/ui/Loading/Loading';
import NoticeBox from 'components/ui/NoticeBox';

import {
  GenericMessage,
  LoginMessage,
  ProfileMessage,
  ProfileSelectionMessage,
} from 'constants/i18n.messages';

import { useAllCompanyProfiles } from 'hooks/useAllCompanyProfiles';
import { useGetCompanyRoles } from 'hooks/useGetCompanyRoles';
import { useIdentity } from 'hooks/useIdentity';
import { useSelectedRole } from 'hooks/useSelectedRole';

import { margin } from 'styles/compLib';

import { companyProfileText } from 'utils/ciam';
import { CUSTOMER_ROLE_IDENTIFIER } from 'utils/cookie';
import { useNavigate } from 'utils/navigation';
import { createRelativeUrlTo } from 'utils/route';

import { AccountColor, InitialsAvatar } from '../../../InitialsAvatar/InitialsAvatar';
import ProfileSelectOption from '../../../ProfileSelectOption';
import CustomerAvatar from '../CustomerAvatar';
import profileStyles from '../ProfileNavigation.module.css';
import { useDoLogout } from '../useDoLogout';
import styles from './MultiProfileContent.module.css';
import { AdminLink, CodeGeneratorLink, JourneysLink, ProfileLink, ReportLink } from './links';
import { useOnRoleChange } from './useOnRoleChange/useOnRoleChange';

const ProfileWrapper = ({
  hasSeveralProfiles,
  children,
}: {
  hasSeveralProfiles?: boolean;
  children: ReactNode;
}) => {
  const wrappedChildren = (
    <Box
      as="div"
      spacing="s16Column"
      separator={<Line border="solidBlockStart" />}
      className={margin.s16Y}
    >
      {children}
    </Box>
  );

  return !hasSeveralProfiles ? (
    wrappedChildren
  ) : (
    <fieldset className={styles.profileWrapper}>
      <legend className={visuallyHidden}>
        <LocalizedMessage id={ProfileSelectionMessage.SELECT_PROFILE_TO_START} />
      </legend>

      {wrappedChildren}
    </fieldset>
  );
};

const ProfileLabel = ({
  color,
  text,
  children,
}: {
  color?: AccountColor;
  text: string;
  children: ReactNode;
}) => (
  <Box as="div" spacing="s08Row" className={styles.profileText} data-testid="profile-label">
    {color ? <InitialsAvatar text={text} color={color} /> : <CustomerAvatar />}
    <div>
      <Text as={'span'} size={16} semibold className={styles.threeLines}>
        {text}
      </Text>
      <div>{children}</div>
    </div>
  </Box>
);

export const MultiProfileContent = ({ onClose }: { onClose: () => void }) => {
  const intl = useIntl();
  const [identity] = useIdentity();
  const navigate = useNavigate();
  const { firstName, lastName } = identity || {};
  const { selectedRole, setSelectedRole } = useSelectedRole();
  const { companyProfiles, loading } = useAllCompanyProfiles();
  const { customerRole } = useGetCompanyRoles();
  const hasSeveralProfiles = companyProfiles
    ? companyProfiles.length + (customerRole ? 1 : 0) > 1
    : undefined;
  const onRoleChange = useOnRoleChange();

  const { doLogout, loading: isLogoutLoading } = useDoLogout(onClose);

  const onRoleSelected = useCallback(
    async (role: string) => {
      pushProfileSelectEvent(role, identity);
      if (onRoleChange.navigateTo) {
        await navigate(
          createRelativeUrlTo({
            ...onRoleChange.navigateTo,
            search: {
              ...onRoleChange.navigateTo.search,
              role, // next page will change the role according to this search parameter
            },
          }),
        );
      } else {
        await setSelectedRole(role);
      }
    },
    [onRoleChange.navigateTo, setSelectedRole, identity, navigate],
  );

  if (!identity?.isCiamUser) return null;

  return (
    <Box as="section" variant="s16Box" className={styles.container}>
      <header className={styles.header}>
        <Button
          aria-label={intl.formatMessage({ id: GenericMessage.CLOSE })}
          variant="s02Circle"
          onClick={onClose}
          data-testid="profile-navigation-close-button"
        >
          <CancelIcon color="var(--color-green10)" />
        </Button>

        <div className={profileStyles.userInfo}>
          <Text
            paragraph={18}
            semibold
            className={profileStyles.userName}
            data-testid="profile-navigation-name"
          >
            {firstName ? (
              <>
                <LocalizedMessage
                  id={ProfileSelectionMessage.GREETING}
                  values={{ name: firstName }}
                />
                {'!'}
              </>
            ) : (
              <LocalizedMessage
                id={ProfileMessage.PROFILE_NAVIGATION_BUTTON_FIRST_NAME_PLACEHOLDER}
              />
            )}
          </Text>
        </div>
      </header>

      {onRoleChange.showWarning && hasSeveralProfiles && (
        <NoticeBox
          color="yellow27"
          variant="smallCard"
          Icon={InfoCircleLinesIcon}
          className={margin.s16Top}
          data-testid="purchase-flow-warning"
        >
          <LocalizedMessage id={ProfileSelectionMessage.PURCHASE_FLOW_WARNING} />
        </NoticeBox>
      )}

      <ProfileWrapper hasSeveralProfiles={hasSeveralProfiles}>
        {!!customerRole && !!companyProfiles?.length && (
          <Box as="div" key={CUSTOMER_ROLE_IDENTIFIER} spacing="s08Column">
            {hasSeveralProfiles ? (
              <ProfileSelectOption
                type="customer"
                key={CUSTOMER_ROLE_IDENTIFIER}
                name="profile-option"
                text={companyProfileText(customerRole)}
                truncate={true}
                id={`option_${CUSTOMER_ROLE_IDENTIFIER}`}
                onChange={() => onRoleSelected(CUSTOMER_ROLE_IDENTIFIER)}
                canPurchase
                checked={!selectedRole || selectedRole === CUSTOMER_ROLE_IDENTIFIER}
              />
            ) : (
              <ProfileLabel text={`${firstName} ${lastName}`}>
                <LocalizedMessage id={ProfileSelectionMessage.CUSTOMER_PROFILE} />
              </ProfileLabel>
            )}
            {selectedRole === CUSTOMER_ROLE_IDENTIFIER && (
              <Box as="nav" spacing="s08Column">
                <JourneysLink onClick={onClose} />
              </Box>
            )}
          </Box>
        )}

        {(companyProfiles ?? []).map(({ id, name, name2, canPurchase, isAdmin, roles }) => {
          const isTravelAgent = roles.includes(JwtCompanyRole.ForteTravelAgencyClerk);
          return (
            <Box as="div" key={id} spacing="s08Column">
              {hasSeveralProfiles ? (
                <ProfileSelectOption
                  type={isTravelAgent ? 'travelAgent' : 'company'}
                  key={id}
                  name="profile-option"
                  text={companyProfileText({ name, name2 })}
                  truncate={true}
                  canPurchase={canPurchase}
                  companyId={id.split('-').at(0)}
                  id={`option_${id}`}
                  onChange={() => onRoleSelected(id)}
                  checked={selectedRole === id}
                />
              ) : (
                <ProfileLabel
                  text={companyProfileText({ name, name2 })}
                  color={isTravelAgent ? 'yellow' : 'blue'}
                >
                  <LocalizedMessage
                    id={
                      isTravelAgent
                        ? ProfileSelectionMessage.TRAVEL_AGENT_PROFILE
                        : ProfileSelectionMessage.COMPANY_PROFILE
                    }
                  />
                </ProfileLabel>
              )}

              {id === selectedRole && (
                <Box as="nav" spacing="s08Column">
                  {canPurchase && <JourneysLink onClick={onClose} />}
                  {roles.includes(JwtCompanyRole.ForteDiscountCodeManager) && (
                    <CodeGeneratorLink onClick={onClose} />
                  )}
                  {roles.includes(JwtCompanyRole.B2BReportViewer) && (
                    <ReportLink onClick={onClose} />
                  )}
                  {isAdmin && <AdminLink onClick={onClose} />}
                </Box>
              )}
            </Box>
          );
        })}
        {loading && <Loading center animation="bars" size={5} color="var(--color-grey17)" />}
        {!companyProfiles?.length && (
          <Box as="nav">
            <JourneysLink showIcon onClick={onClose} />
          </Box>
        )}
        <Box as="nav">
          <ProfileLink onClick={onClose} />
        </Box>
      </ProfileWrapper>

      <ActionButton
        loading={isLogoutLoading}
        stretch
        grey30
        data-testid="logout-link"
        onClick={doLogout}
      >
        <LocalizedMessage id={LoginMessage.LOGOUT} />
      </ActionButton>
    </Box>
  );
};
