import {
  JwtCompanyRole,
  LineItemFragment,
  OrderGroupJourneyFragment,
  PassengerType,
  ProductType,
  SalesSessionFragment,
  SessionGroupJourneyFragment,
  SummaryFragment,
  VehicleType,
} from 'backend/types.codegen';

import { Passenger } from 'hooks/useSetSearchParameters';

import { createDate } from 'utils/date';
import { getAdditionalServices } from 'utils/getAdditionalServices';
import { keys } from 'utils/object';

import { Maybe } from 'types/utils';
import { multiTicketToItem } from './multiTicket/multiTicketToItem';
import { GA4ItemCategory, GA4ProductItem, GA4PurchaseFlowType, PassengerWithType } from './types';

export const getEcommerceDetails = (
  salesSession: SalesSessionFragment,
  purchaseFlowType: GA4PurchaseFlowType,
) => {
  return {
    currency: 'EUR',
    value: salesSession.amountToPay ? salesSession.amountToPay / 100 : 0,
    items: getProductItems(salesSession, purchaseFlowType),
    transaction_id: salesSession.orderNumber,
  };
};

export const getJourneyDetails = (salesSession: SalesSessionFragment) => {
  const [outboundJourney, returnJourney] = salesSession?.groupJourneys ?? [];
  const { seriesTicket } = salesSession;

  const outboundTimestamp = outboundJourney
    ? createDate(outboundJourney.departureTime).valueOf()
    : undefined;
  const returnTimestamp = returnJourney
    ? createDate(returnJourney.departureTime).valueOf()
    : undefined;

  return {
    from_station: outboundJourney ? outboundJourney.departureStation : seriesTicket?.stations[0],
    to_station: outboundJourney ? outboundJourney.arrivalStation : seriesTicket?.stations[1],
    departure_date: outboundTimestamp,
    return_date: returnTimestamp,
  };
};
/*
 * Format for return value: "adult:3, child:1, pet:1, bike:1, wheelchair:1"
 */
export const getPurchaseFlowDetailFromSalesSession = (salesSession?: SalesSessionFragment) => {
  if (!salesSession) return '';

  if (salesSession.seriesTicket && salesSession.sessionSummary) {
    const multiticket = salesSession.sessionSummary.breakdown.find(
      (summaryItem) => summaryItem.productType === 'MULTI_TICKET',
    );
    if (multiticket?.values?.passengerType) {
      return multiticket?.values?.passengerType?.toLowerCase() + ':1';
    }
  }

  const [outboundJourney] = salesSession.groupJourneys ?? [];
  if (!outboundJourney) return '';

  const purchaseFlowDetail = getTicketAmountsByPassengerTypeFromGroupJourney(outboundJourney);
  const groupJourney = salesSession?.groupJourneys[0];

  keys(productTypePurchaseFlowDetailMap).forEach((key) => {
    purchaseFlowDetail[key] = groupJourney?.summary?.breakdown.find(
      (breakdownItem) => breakdownItem.productType === productTypePurchaseFlowDetailMap[key],
    )?.count;
  });

  const wheelchairs = getWheelChairFees(salesSession);
  purchaseFlowDetail['wheelchair'] = wheelchairs.length;

  return Object.keys(purchaseFlowDetail)
    .filter((key) => {
      return purchaseFlowDetail[key] && purchaseFlowDetail[key] > 0;
    })
    .map((key) => {
      return key + ':' + purchaseFlowDetail[key];
    })
    .join(', ');
};

/*
 * Format for return value: "adult:3, child:1, pet:1, bike:1, wheelchair:1"
 */
export const getPurchaseFlowDetailFromPassengers = (passengers: Passenger[]) => {
  const ticketAmountsByPassengerType = getTicketAmountsByPassengerType(passengers);
  const additionalServices = getAdditionalServices(passengers);

  const purchaseFlowDetail = {
    ...ticketAmountsByPassengerType,
    bike: additionalServices.bicycles,
    car: additionalServices.cars,
    motorcycle: additionalServices.motorcycles,
    pet: additionalServices.pets,
    wheelchair: additionalServices.wheelchairs,
  };

  return keys(purchaseFlowDetail)
    .filter((key) => {
      return purchaseFlowDetail[key] > 0;
    })
    .map((key) => {
      return key + ':' + purchaseFlowDetail[key];
    })
    .join(', ');
};

export const getPurchaseFlowDetailFromJourney = (journey: OrderGroupJourneyFragment) => {
  const { passengerJourneys, vehicles } = journey;
  const passengerTypes =
    passengerJourneys?.reduce<PassengerWithType[]>((acc, passengerJourney) => {
      if (passengerJourney.passenger?.type) {
        acc.push({
          type: passengerJourney.passenger?.type,
        });
      }
      return acc;
    }, []) ?? [];

  const ticketAmountsByPassengerType = getTicketAmountsByPassengerType(passengerTypes);
  const additionalProducts = journey.additionalProducts ?? [];

  const purchaseFlowDetail = {
    ...ticketAmountsByPassengerType,
    bike: additionalProducts
      .filter((product) => product.type === ProductType.Bicycle)
      .reduce((sum, product) => sum + product.count, 0),
    pet: additionalProducts
      .filter((product) => product.type === ProductType.Pet)
      .reduce((sum, product) => sum + product.count, 0),
    wheelchair: additionalProducts
      .filter((product) => product.type === ProductType.Wheelchair)
      .reduce((sum, product) => sum + product.count, 0),
    car: vehicles?.filter((vehicle) => vehicle.vehicleDetails.vehicleType === VehicleType.Car)
      .length,
    motorcycle: vehicles?.filter(
      (vehicle) => vehicle.vehicleDetails.vehicleType === VehicleType.Motorcycle,
    ).length,
  };

  return keys(purchaseFlowDetail)
    .filter((key) => {
      return purchaseFlowDetail[key] && purchaseFlowDetail[key] > 0;
    })
    .map((key) => {
      return key + ':' + purchaseFlowDetail[key];
    })
    .join(', ');
};

export const getProductItems = (
  salesSession?: SalesSessionFragment,
  purchaseFlowType?: GA4PurchaseFlowType,
): GA4ProductItem[] => {
  if (!salesSession) return [];

  if (purchaseFlowType === 'SELF_SERVICE_CHANGE' && salesSession.sessionSummary) {
    return getItemsFromSessionSummary(salesSession.sessionSummary);
  }

  if (salesSession.groupJourneys.length > 0) {
    return salesSession.groupJourneys.flatMap((journey) =>
      getProductItemsFromGroupJourney(journey),
    );
  }
  if (salesSession.seriesTicket) {
    return [multiTicketToItem(salesSession.seriesTicket)];
  }
  return [];
};

const getProductItemsFromGroupJourney = (
  groupJourney: SessionGroupJourneyFragment,
): GA4ProductItem[] => {
  const trafficType = groupJourney.trafficType;
  const ticketType = 'SINGLE';

  return (
    groupJourney.summary?.breakdown.map((summaryLineItem) => {
      const item_name = isTrainTicket(summaryLineItem)
        ? trafficType + '_' + ticketType
        : summaryLineItem.product ?? summaryLineItem.productType;

      return {
        item_name: item_name.toLowerCase(),
        item_category: getCategory(summaryLineItem),
        quantity: summaryLineItem.count,
        price: summaryLineItem.unitPrice.grossAmount / 100,
      };
    }) || []
  );
};

const getItemsFromSessionSummary = (summary: SummaryFragment): GA4ProductItem[] => {
  return summary?.breakdown.map((summaryLineItem) => {
    const item_name = summaryLineItem.product ?? summaryLineItem.productType;

    return {
      item_name: item_name.toLowerCase(),
      item_category: getCategory(summaryLineItem),
      quantity: summaryLineItem.count,
      price: summaryLineItem.unitPrice.grossAmount / 100,
    };
  });
};

const getCategory = (summaryItem: LineItemFragment): GA4ItemCategory => {
  const key = summaryItem.product ?? summaryItem.productType;
  return forteProductNameToCategoryMap[key] ?? 'additional services';
};

const isTrainTicket = (summaryLineItem: LineItemFragment): boolean => {
  return summaryLineItem.productType.startsWith('SINGLE');
};

export const getPurchaseFlowType = (salesSession: SalesSessionFragment): GA4PurchaseFlowType => {
  if (salesSession?.seriesTicket) {
    return 'BUY_SERIES';
  }

  return 'BUY_SINGLE';
};

/* Example return value:
 *  {
 *    adult: 2,
 *    assistant: 0,
 *    child: 1,
 *    conscript: 0,
 *    pensioner: 0,
 *    student: 0
 *  }
 */
const getTicketAmountsByPassengerType = (passengers: PassengerWithType[]) => {
  const passengerTypes = Object.values(PassengerType);
  const ticketAmounts: Record<string, number | undefined> = {};
  passengerTypes.forEach((type) => {
    ticketAmounts[type.toLowerCase()] = passengers.filter(
      (passenger) => passenger.type === type,
    ).length;
  });

  return ticketAmounts;
};

const getTicketAmountsByPassengerTypeFromGroupJourney = (
  groupJourney: SessionGroupJourneyFragment,
) => {
  const passengerTypes = Object.values(PassengerType);
  const ticketAmounts: Record<string, number | undefined> = {};
  const passengerTypesInJourney = groupJourney.passengerJourneys?.flatMap((passengerJourney) => {
    return passengerJourney?.passenger?.type;
  });

  if (!passengerTypesInJourney) return {};

  passengerTypes.forEach((type) => {
    ticketAmounts[type.toLowerCase()] = passengerTypesInJourney.filter(
      (passengerType) => passengerType === type,
    ).length;
  });
  return ticketAmounts;
};

const getWheelChairFees = (salesSession: SalesSessionFragment) => {
  return salesSession.groupJourneys
    .flatMap((groupJourney) => {
      return groupJourney.passengerJourneys?.flatMap((passengerJourney) => {
        return passengerJourney.otherFees;
      });
    })
    .filter((otherFee) => otherFee && otherFee.product === 'WHEELCHAIR');
};

const forteProductNameToCategoryMap: Record<string, GA4ItemCategory | undefined> = {
  ADJACENT_SEAT_ECO: 'seats and beds',
  ADJACENT_SEAT_EXTRA: 'seats and beds',
  ADJACENT_SEAT_RESTAURANT_WAGON: 'seats and beds',
  PET: 'additional services',
  CANCELLATION_INSURANCE_JOURNEY: 'additional services',
  CANCELLATION_INSURANCE_VEHICLE: 'additional services',
  BICYCLE: 'additional services',
  BICYCLE_ALLOTMENT: 'additional services',
  ECO_CLASS_SEAT: 'seats and beds',
  EXTRA_CLASS_SEAT: 'seats and beds',
  SEAT_UPSTAIRS_RESTAURANT_WAGON: 'seats and beds',
  TRAVEL_RIGHT: 'additional services',
  CHANGE_FEE_JOURNEY: 'additional services',
  SINGLE_TICKET: 'train tickets',
  SEAT_CABIN: 'seats and beds',
  CHANGE_AND_CANCELLATION: 'additional services',
  WAGON_MAP: 'additional services',
  MULTITICKET_EXTRA: 'train tickets',
  MULTITICKET_CAMPAIGN: 'train tickets',
  MULTITICKET_ECO: 'train tickets',
  MULTITICKET_COMMUTER: 'train tickets',
  MULTITICKET_COMMUTER_CAMPAIGN: 'train tickets',
  SEAT_IN_NEGOTIATION_CABIN: 'seats and beds',
  SEAT_IN_WORKING_CABIN: 'seats and beds',
  SEAT_IN_FAMILY_CABIN: 'seats and beds',
  BERTH_CABIN: 'seats and beds',
  BERTH_CABIN_EDM: 'seats and beds',
  BERTH_CABIN_WITH_SHOWER: 'seats and beds',
  BERTH_CABIN_CEMT: 'seats and beds',
  WHEELCHAIR: 'additional services',
  HSL_TICKET: 'additional services',
  HSL_SERVICE_FEE: 'additional services',
  VEHICLE: 'additional services',
  LONG_VEHICLE: 'additional services',
  EXTRA_LONG_VEHICLE: 'additional services',
  MOTORCYCLE: 'additional services',
  CAR: 'additional services',
  NIGHT_TRAIN_BREAKFAST_A1: 'meals',
  NIGHT_TRAIN_BREAKFAST_A2: 'meals',
  NIGHT_TRAIN_BREAKFAST_A3: 'meals',
  NIGHT_TRAIN_BREAKFAST_A4: 'meals',
  NIGHT_TRAIN_BREAKFAST_A5: 'meals',
};

const productTypePurchaseFlowDetailMap = {
  bike: 'ADDITIONAL_SERVICE_BICYCLE',
  pet: 'ADDITIONAL_SERVICE_PET',
  car: 'VEHICLE',
  motorcycle: 'MOTORCYCLE',
};

export const formatDate = (date: unknown) => {
  if (typeof date === 'string') {
    return date.replaceAll('-', '');
  }

  if (date instanceof Date) {
    return date.toISOString().split('T')[0].replaceAll('-', '');
  }

  return '';
};

export const toYesNo = (value: any) => (value ? 'yes' : 'no');

export const resolveUserRole = (role: Maybe<JwtCompanyRole>) => {
  switch (role) {
    case JwtCompanyRole.B2BAdmin:
    case JwtCompanyRole.B2BReportViewer:
    case JwtCompanyRole.ForteCorporateUser:
    case JwtCompanyRole.ForteDiscountCodeManager:
    case JwtCompanyRole.TravelAgentAdmin:
      return 'CORPORATE';
    case JwtCompanyRole.ForteTravelAgencyClerk:
      return 'TRAVEL_AGENCY_CLERK';
    default:
      return 'CONSUMER';
  }
};
