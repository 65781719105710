import differenceInMinutes from 'date-fns/differenceInMinutes';
import { useCallback, useEffect, useMemo } from 'react';

import { useNow } from 'backend/hooks/useNow';
import { GET_SALES_SESSION } from 'backend/hooks/useSalesSession';

import { LocalizedMessage } from 'components/i18n/LocalizedMessage/LocalizedMessage';

import { BookingMessage, BusConnectionsMessage, UnitMessage } from 'constants/i18n.messages';

import { useBackendClient } from 'contexts/backendClient';
import { useSiteData } from 'contexts/siteData';

import { useSalesSessionFromUrlParam } from 'hooks/salesSession/useSalesSessionFromUrlParam';
import { useStoredState } from 'hooks/useStoredState';

import { StorageKey } from 'types/stateStore';

import { salesSessionAboutToExpire } from 'analytics/analytics';
import { createDate } from 'utils/date';

const calcDiff = (date: Date, now: Date) =>
  Math.max(
    differenceInMinutes(date, now, {
      roundingMethod: 'ceil',
    }),
    0,
  );

export const useExpirationNotification = () => {
  const client = useBackendClient();
  const { salesSessionExpirationNotificationThreshold } = useSiteData();

  const [dismissedExpiration, setDismissedExpiration] = useStoredState(
    StorageKey.DismissSalesSessionExpiration,
  );

  const { salesSession } = useSalesSessionFromUrlParam({
    requireIdInUrl: false,
    throwOnError: false,
  });

  const now = useNow();

  const { validUntil, externalServicePaymentStartDeadline } = salesSession ?? {};
  const dismissed = !!(dismissedExpiration && dismissedExpiration === salesSession?.id);

  const { validFor, titleKey, bodyKey, refreshSalesSession } = useMemo(() => {
    const validUntilDate = !!validUntil && createDate(validUntil);

    const externalServicePaymentStartDeadlineDate =
      !!externalServicePaymentStartDeadline && createDate(externalServicePaymentStartDeadline);

    if (
      validUntilDate &&
      (!externalServicePaymentStartDeadlineDate ||
        validUntilDate <= externalServicePaymentStartDeadlineDate)
    ) {
      const validFor = calcDiff(validUntilDate, now);

      return {
        validFor: validFor <= salesSessionExpirationNotificationThreshold ? validFor : null,
        titleKey: BookingMessage.EXPIRATION_WARNING_TITLE,
        bodyKey:
          validFor <= 1
            ? BookingMessage.EXPIRATION_WARNING_BODY_SOON
            : BookingMessage.EXPIRATION_WARNING_BODY,
        refreshSalesSession: true,
      };
    }

    if (
      externalServicePaymentStartDeadlineDate &&
      (!validUntilDate || externalServicePaymentStartDeadlineDate < validUntilDate)
    ) {
      const validFor = calcDiff(externalServicePaymentStartDeadlineDate, now);

      return {
        validFor: validFor <= 5 ? validFor : null,
        titleKey: BusConnectionsMessage.EXPIRING_PAYMENT_NOTIFICATION_TITLE,
        bodyKey:
          validFor <= 1
            ? BusConnectionsMessage.EXPIRING_PAYMENT_NOTIFICATION_BODY_SOON
            : BusConnectionsMessage.EXPIRING_PAYMENT_NOTIFICATION_BODY,
      };
    }

    return {
      validFor: null,
    };
  }, [
    externalServicePaymentStartDeadline,
    now,
    salesSessionExpirationNotificationThreshold,
    validUntil,
  ]);

  const isAboutToExpire = validFor ? validFor <= 10 : false;

  useEffect(() => {
    if (isAboutToExpire) {
      salesSessionAboutToExpire();
    }
  }, [isAboutToExpire]);

  useEffect(() => {
    if (validFor !== 0 || !refreshSalesSession) {
      return;
    }

    const handle = window.setTimeout(() => {
      client.refetchQueries({ include: [GET_SALES_SESSION] });
    }, 10000);

    return () => {
      window.clearTimeout(handle);
    };
  }, [client, refreshSalesSession, validFor]);

  const onClose = useCallback(() => {
    if (salesSession) {
      setDismissedExpiration(salesSession.id);
    }
  }, [salesSession, setDismissedExpiration]);

  return {
    show: !dismissed && validFor !== null,
    title: <LocalizedMessage id={titleKey} />,
    body: (
      <LocalizedMessage
        id={bodyKey}
        values={{
          time: (
            <>
              {validFor} <LocalizedMessage id={UnitMessage.MINUTE} values={{ value: validFor }} />
            </>
          ),
        }}
      />
    ),
    onClose,
  };
};
