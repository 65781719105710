import { useEffect } from 'react';

import { createDate } from 'utils/date';
import { minutesToMs, secondsToMs } from 'utils/ms';

import { setPaymentStore } from './setPaymentStore';

const REMOVE_PAYMENT_INFOS_AFTER = minutesToMs(30);
const POLL_INTERVAL = secondsToMs(60);

const checkPaymentInfos = () => {
  setPaymentStore((paymentInfos) =>
    paymentInfos.filter(
      ({ timestamp }) => createDate(timestamp).getTime() + REMOVE_PAYMENT_INFOS_AFTER > Date.now(),
    ),
  );
};

export const useClearOldPaymentInfos = () => {
  useEffect(() => {
    checkPaymentInfos();

    const handle = window.setInterval(checkPaymentInfos, POLL_INTERVAL);

    return () => {
      window.clearInterval(handle);
    };
  }, []);
};
