import(/* webpackMode: "eager", webpackExports: ["HookHolder"] */ "/home/runner/work/vr-web/vr-web/packages/front/app/[locale]/(contentfulPages)/common/HookHolder.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/app/[locale]/(contentfulPages)/common/Layout.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/vr-web/vr-web/packages/front/app/[locale]/(contentfulPages)/common/RouteListeners.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LocalizedMessage"] */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/i18n/LocalizedMessage/LocalizedMessage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ModalHandler"] */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/structural/ModalHandler/ModalHandler.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RouteProvider","useRouteState"] */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/structural/Route/Route.context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TestTools"] */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/structural/TestTools/TestTools.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/structural/TranslationsProvider/ClientIntlProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CookieConsent"] */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/ui/CookieConsent/CookieConsent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/ui/Footer/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/ui/Header/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/ui/Notifications/Notifications.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/ui/ScreenReaderAnnouncements/ScreenReaderAnnouncements.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/ui/ScreenReaderAnnouncements/ScreenReaderAnnouncementsProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ToastContainer"] */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/ui/Toast/ToastContainer.tsx");
