import { useCallback, useEffect } from 'react';

import { localStorage } from 'utils/storage';

import { useVariable } from './useVariable';

type EventType = 'loggedIn' | 'loggedOut';

const messageKey = 'crossTabMessage';

export const useCrossTabMessaging = (onMessage?: (eventType: EventType) => void) => {
  const onMessageRef = useVariable(onMessage);

  const sendCrossTabMessage = useCallback((eventType: EventType) => {
    localStorage.setItem(messageKey, eventType);
    localStorage.removeItem(messageKey);
  }, []);

  useEffect(() => {
    const onStorage = (event: StorageEvent) => {
      const handler = onMessageRef.current;

      if (event.key !== messageKey || !event.newValue || !handler) {
        return;
      }

      handler(event.newValue as EventType);
    };

    window.addEventListener('storage', onStorage);

    return () => {
      window.removeEventListener('storage', onStorage);
    };
  }, [onMessageRef]);

  return { sendCrossTabMessage };
};
