import classNames from 'classnames';
import { useIntl } from 'react-intl';

import { LOCALE, LOCALES } from 'constants/i18n';

import { useLocation } from 'utils/navigation';

import { useId } from 'react';
import { Language } from './Language/Language';
import styles from './LanguageList.module.css';

const navLabel: Record<LOCALE, string> = {
  [LOCALE.Fi]: 'Sivuston kieli',
  [LOCALE.En]: 'page language',
  [LOCALE.Sv]: 'webbplatsens språk',
};

export const LanguageList = ({
  className,
  routeLocalizations,
}: {
  className?: string;
  routeLocalizations: Record<LOCALE, string>;
}) => {
  const location = useLocation();
  const { locale: currentLocale } = useIntl();

  const labelId = useId();

  return (
    <nav aria-labelledby={labelId}>
      <div hidden id={labelId}>
        {Object.values(LOCALE)
          .map((locale) => navLabel[locale])
          .join(', ')}
      </div>
      <ul className={classNames(className, styles.list)}>
        {Object.values(LOCALE).map((locale) => (
          <li className={styles.listItem} key={locale} lang={locale}>
            <Language
              name={locale}
              url={routeLocalizations[locale]}
              isCurrent={locale.toUpperCase() === currentLocale.toUpperCase()}
              location={location}
              label={LOCALES[locale].label}
            />
          </li>
        ))}
      </ul>
    </nav>
  );
};
