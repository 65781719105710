'use client';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { LOCALE } from 'constants/i18n';
import { AccessibilityMessage } from 'constants/i18n.messages';

import { useSiteData } from 'contexts/siteData';

import JumpToPurchaseFlow from '../Header/JumpToPurchaseFlow';
import { VRLogoIcon } from '../Icons';
import LanguageList from '../LanguageList';
import Link from '../Link';
import styles from './HeaderLayout.module.css';

export const HeaderLayout = ({
  userButtonContent,
  menuButtonContent,
  hideJumpToPurchaseFlowButton,
  children,
  routeLocalizations,
}: {
  userButtonContent?: ReactNode;
  menuButtonContent?: ReactNode;
  hideJumpToPurchaseFlowButton?: boolean;
  children?: ReactNode;
  routeLocalizations?: Record<LOCALE, string>;
}) => {
  const { landingPage } = useSiteData();
  const { formatMessage } = useIntl();

  return (
    <div>
      <div className={styles.topBar}>
        <div className={styles.container}>
          <Link
            className={styles.logoLink}
            aria-label={formatMessage({ id: AccessibilityMessage.TO_FRONT_PAGE })}
            data-testid="vr-logo"
            href={landingPage}
          >
            <VRLogoIcon size={2.5} />
          </Link>

          {!hideJumpToPurchaseFlowButton && <JumpToPurchaseFlow />}

          {routeLocalizations && (
            <div className={styles.languageList}>
              <LanguageList routeLocalizations={routeLocalizations} />
            </div>
          )}

          {!!userButtonContent && <div className={styles.userLink}>{userButtonContent}</div>}

          {!!menuButtonContent && <div>{menuButtonContent}</div>}
        </div>
      </div>

      {children}
    </div>
  );
};
