import classNames from 'classnames';
import { forwardRef } from 'react';

import { ChevronDownIcon } from 'components/ui/Icons';

import { useIdentity } from 'hooks/useIdentity';

import ProfileTooltip from '../ProfileTooltip';
import { MultiProfileContent } from './Content/MultiProfileContent';
import { SingleProfileContent } from './Content/SingleProfileContent';
import styles from './ProfileButton.module.css';

export const ProfileButton = forwardRef<
  HTMLButtonElement,
  { onClick: () => void; className?: string; expanded?: boolean }
>(function ProfileButton({ onClick, className, expanded }, ref) {
  const [identity] = useIdentity();

  if (!identity?.isAuthenticated) return null;

  return (
    <>
      <button
        className={classNames(styles.profileButton, className)}
        data-testid="open-profile-menu"
        data-analytics-id="open-profile-menu"
        onClick={onClick}
        aria-haspopup="menu"
        aria-labelledby="profile-button-content"
        aria-expanded={expanded}
        ref={ref}
      >
        {identity?.isCiamUser ? <MultiProfileContent /> : <SingleProfileContent />}

        <ChevronDownIcon color={'var(--color-green08)'} />
      </button>
      <ProfileTooltip />
    </>
  );
});
