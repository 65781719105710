import LocalizedMessage from 'components/i18n/LocalizedMessage';
import { BusinessTravellerIcon, UserDefaultIcon } from 'components/ui/Icons';

import { ProfileMessage } from 'constants/i18n.messages';

import { UserIdentity } from 'contexts/identity';

import { useIdentity } from 'hooks/useIdentity';

import { truncate } from 'utils/string';

import styles from '../ProfileButton.module.css';

const getUserLabel = (user: UserIdentity) => {
  switch (true) {
    case user.isEmployee:
      return ProfileMessage.VR_USER;
    default:
      return null;
  }
};

export const SingleProfileContent = () => {
  const [identity] = useIdentity();
  if (!identity) return null;
  const { firstName, isConsumer } = identity;

  const userLabel = identity && getUserLabel(identity);

  return (
    <>
      <span id="profile-menu-nav-icon">
        {!isConsumer ? (
          <BusinessTravellerIcon color="var(--color-grey10)" />
        ) : (
          <UserDefaultIcon color="var(--color-grey10)" />
        )}
      </span>

      <div className={styles.contentContainer} id="profile-button-content">
        <span className={styles.firstName}>
          {firstName ? (
            truncate(firstName, 16)
          ) : (
            <LocalizedMessage
              id={ProfileMessage.PROFILE_NAVIGATION_BUTTON_FIRST_NAME_PLACEHOLDER}
            />
          )}
        </span>

        {userLabel && (
          <div className={styles.userLabel}>
            <LocalizedMessage id={userLabel} />
          </div>
        )}
      </div>
    </>
  );
};
