import { ColorEnum } from '@vrfi/web-components';

import { UserIndicatorFilledIcon, UserIndicatorOutlineIcon } from 'components/ui/Icons';

type Props = {
  outline?: boolean;
};

const CustomerAvatar = ({ outline }: Props) =>
  outline ? (
    <UserIndicatorOutlineIcon
      color={ColorEnum.green10}
      size={2}
      data-testid="customer-avatar-outline"
    />
  ) : (
    <UserIndicatorFilledIcon color={ColorEnum.green10} size={2} data-testid="customer-avatar" />
  );

export default CustomerAvatar;
