import { useClaimSession } from 'backend/hooks/useClaimSale';

import { SalesSessionError } from 'components/structural/ErrorBoundary';

import { useCrossTabMessaging } from 'hooks/useCrossTabMessaging';

import { useThrowError } from '../useThrowError';
import { useSalesSessionUrlParam } from './useSalesSessionUrlParam';

export const useClaimSalesSessionAfterLogin = () => {
  const [claimSale] = useClaimSession();
  const { saleId } = useSalesSessionUrlParam();
  const throwError = useThrowError();

  useCrossTabMessaging(async (eventType) => {
    if (eventType === 'loggedIn' && saleId) {
      try {
        await claimSale({ variables: { saleId } });
      } catch (e) {
        throwError(new SalesSessionError('failed to claim session'));
      }
    }
  });
};
