import { useIntl } from 'react-intl';

import LocalizedMessage from 'components/i18n/LocalizedMessage';
import Link from 'components/ui/Link';

import { LoginMessage } from 'constants/i18n.messages';

import { useIdentity } from 'hooks/useIdentity';

import envs from 'utils/envs';

import styles from './ContentfulSiteFooter.module.css';

export const EmployeeLoginLink = () => {
  const [identity] = useIdentity();

  const { locale } = useIntl();

  if (identity?.isAuthenticated) {
    return <div>&nbsp;</div>; // Keep layout intact by reserving space for the component
  }

  const state = locale.padEnd(5, '-'); // We need to pad the state param because the minimum length is 5 chars

  return (
    <Link
      className={styles.footerLink}
      forceInternal
      data-testid="footer-ad-login-link"
      href={`${envs.NEXT_PUBLIC_AD_LOGIN_PATH}?client_id=${envs.NEXT_PUBLIC_FORTE_CLIENT_ID}&response_type=code&state=${state}`}
    >
      <LocalizedMessage id={LoginMessage.FOR_EMPLOYEES} />
    </Link>
  );
};
