import { GA4ProductItem } from 'analytics/types';

import { SeriesTicketFragment } from 'backend/types.codegen';

import { MultiTicketItemName } from './types';

export const multiTicketToItem = (seriesTicket: SeriesTicketFragment): GA4ProductItem => {
  const item_name: MultiTicketItemName =
    (seriesTicket.ticketClass === 'EXTRA' && 'long_distance_series_extra') ||
    (seriesTicket.type === 'COMMUTER_SERIES' && 'commuter_series') ||
    'long_distance_series';

  const item_variant = [
    `validity_from:${seriesTicket.usageStartTime}`,
    `duration:${seriesTicket.periodLengthDays}`,
    `ticket_count:${seriesTicket.ticketCount}`,
  ].join(', ');

  return {
    item_category: 'train tickets',
    item_name,
    price: seriesTicket.price / 100,
    quantity: 1,
    item_variant,
    item_id: item_variant,
  };
};
