import pushToDataLayer from 'analytics/pushToDataLayer';

import { CookieConsentLevels } from 'types/stateStore';

import { createRelativeUrlTo } from 'utils/route';

interface ConsentSettingsEvent {
  event: 'consentSettings';
  consent: CookieConsentLevels;
  timestamp: number;
  pagePath: string;
}

export const pushConsentSettingsEvent = (consentLevels: CookieConsentLevels) => {
  const pagePath = createRelativeUrlTo(window.location);

  if (consentLevels.level1) {
    pushToDataLayer<ConsentSettingsEvent>({
      event: 'consentSettings',
      consent: {
        level1: consentLevels.level1,
        level2: consentLevels.level2,
        level3: consentLevels.level3,
        level4: consentLevels.level4,
      },
      timestamp: Date.now(),
      pagePath,
    });
  }
};
