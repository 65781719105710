'use client';
import { ColorEnum } from '@vrfi/web-components';

import ContentfulSiteFooter from 'components/content/ContentfulSiteFooter';
import LocalizedMessage from 'components/i18n/LocalizedMessage';
import { LocalizedDate } from 'components/i18n/LocalizedMessages';
import { VRLogoIcon } from 'components/ui/Icons';

import { GenericMessage } from 'constants/i18n.messages';

import envs, { isProduction } from 'utils/envs';

import { Feedback } from './Feedback';
import styles from './Footer.module.css';
import { Versions } from './Versions';

export const Footer = () => {
  return (
    <footer className={styles.container}>
      <Feedback />

      <ContentfulSiteFooter />

      <div className={styles.copyright}>
        <VRLogoIcon color={ColorEnum.white} size={3} />
        <div>
          &copy; <LocalizedMessage id={GenericMessage.COMPANY_NAME} />{' '}
          <LocalizedDate value={new Date()} format="y" />
        </div>
        {envs.NEXT_PUBLIC_SHOW_VERSION_NUMBER && !isProduction && <Versions />}
      </div>
    </footer>
  );
};
