import { CompanyWithRole, ForteJwtRole, UserIdentityFragment } from 'backend/types.codegen';

export type UserIdentity = {
  customerId?: string;
  isAuthenticated: boolean;
  isConsumer: boolean;
  isCorporate?: boolean;
  isEmployee: boolean;
  isVrEmployee: boolean;
  isCiamUser: boolean;
  firstName?: string;
  lastName?: string;
  customerRoles?: ForteJwtRole[];
  companyRoles?: CompanyWithRole[];
  isTramEmployee: boolean;
  isPlEmployee: boolean;
};

export const mapUserFragmentToIdentity = ({
  firstName,
  lastName,
  ...rest
}: UserIdentityFragment): UserIdentity => {
  return {
    ...rest,
    firstName: firstName ?? undefined,
    lastName: lastName ?? undefined,
    companyRoles: rest.companyRoles ?? undefined,
  };
};
