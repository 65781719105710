'use client';
import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { visuallyHiddenToXS } from '@vrfi/web-components/css/utils.module.css';

import LocalizedMessage from 'components/i18n/LocalizedMessage';

import { HeaderMessage } from 'constants/i18n.messages';

import { useCurrentPurchaseFlowStep } from 'hooks/purchaseFlow/useCurrentPurchaseFlowStep';
import { useIsAuthenticated } from 'hooks/useIsAuthenticated';

import { PurchaseFlowStepEnum } from 'types/purchaseFlow';

import { useHasLoginParam, useLocation } from 'utils/navigation';

import { LOCALE } from 'constants/i18n';
import { FocusOn } from '../FocusOn/FocusOn';
import { HeaderLayout } from '../HeaderLayout/HeaderLayout';
import styles from './Header.module.css';
import { LoginLink } from './LoginLink/LoginLink';
import LoginTooltip from './LoginTooltip';
import MainNavigation from './MainNavigation';
import { ProfileButton } from './ProfileButton';
import { ProfileNavigation } from './ProfileNavigation';

type Props = {
  routeLocalizations: Record<LOCALE, string>;
};
function Header({ routeLocalizations }: Props) {
  const [showMainMenu, setShowMainMenu] = useState(false);
  const authenticated = useIsAuthenticated();
  const { formatMessage } = useIntl();
  const location = useLocation();
  const menuButton = useRef<HTMLButtonElement>(null);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const currentPurchaseFlowStep = useCurrentPurchaseFlowStep();

  const onCloseMainMenu = useCallback(() => {
    setShowMainMenu(false);
  }, [setShowMainMenu]);

  const onCloseProfileMenu = useCallback(() => setShowProfileMenu(false), []);
  const onToggleMainMenu = useCallback(() => setShowMainMenu((state) => !state), [setShowMainMenu]);
  const onToggleProfileMenu = useCallback(() => setShowProfileMenu((state) => !state), []);

  useEffect(() => {
    function closeOnEscAndFocusMenuButton(event: KeyboardEvent) {
      if (event.defaultPrevented) return;
      if (event.key === 'Escape') {
        setShowMainMenu((state) => {
          if (state) menuButton.current?.focus();
          return false;
        });
        event.preventDefault();
      }
    }

    function closeMenuOnNavLinkClick(event: MouseEvent) {
      if (event.defaultPrevented) return;
      if (event.target instanceof HTMLAnchorElement) {
        setShowMainMenu(false);
      }
    }

    const nav = document.getElementById('nav') as HTMLElement;
    window.addEventListener('keydown', closeOnEscAndFocusMenuButton);
    nav.addEventListener('click', closeMenuOnNavLinkClick);

    return () => {
      window.removeEventListener('keydown', closeOnEscAndFocusMenuButton);
      nav.removeEventListener('click', closeMenuOnNavLinkClick);
    };
  }, [setShowMainMenu]);

  const currentLocation = `${location.pathname}${location.search}`;
  const cacheLocation = useRef(currentLocation);

  useEffect(
    function closeMenuOnLocationChange() {
      if (currentLocation !== cacheLocation.current) {
        cacheLocation.current = currentLocation;
        setShowMainMenu(false);
      }
    },
    [currentLocation, setShowMainMenu],
  );

  const hasLoginParam = useHasLoginParam();
  const showOverlay = showMainMenu && !hasLoginParam;
  const showProfileNav = showProfileMenu && !hasLoginParam;

  const userButtonRef = useRef<HTMLAnchorElement & HTMLButtonElement>(null);

  return (
    <header>
      <div className={classNames(styles.overlay, { [styles.visible]: showOverlay })} aria-hidden />
      <FocusOn enabled={showMainMenu} onClickOutside={onCloseMainMenu}>
        <HeaderLayout
          routeLocalizations={routeLocalizations}
          hideJumpToPurchaseFlowButton={routeLocalizations.fi === '/'}
          userButtonContent={
            <>
              {authenticated ? (
                <ProfileButton
                  ref={userButtonRef}
                  onClick={onToggleProfileMenu}
                  className={styles.menuButton}
                  expanded={showProfileMenu}
                />
              ) : (
                <LoginLink ref={userButtonRef} className={styles.menuButton} />
              )}
              {currentPurchaseFlowStep === PurchaseFlowStepEnum.OutboundSearchResults && (
                <LoginTooltip />
              )}
            </>
          }
          menuButtonContent={
            <button
              className={styles.menuButton}
              aria-controls="nav"
              aria-haspopup="menu"
              aria-expanded={showMainMenu}
              data-testid="navbar-menu-button"
              id="menu-button"
              onClick={onToggleMainMenu}
              ref={menuButton}
              aria-label={formatMessage({ id: HeaderMessage.MENU })}
            >
              <span className={visuallyHiddenToXS}>
                <LocalizedMessage id={HeaderMessage.MENU} />
              </span>

              <span className={styles.mainMenuIcon} aria-hidden />
            </button>
          }
        >
          <MainNavigation id="nav" isOpen={showMainMenu} routeLocalizations={routeLocalizations} />

          {authenticated && (
            <ProfileNavigation
              open={showProfileNav}
              onClose={onCloseProfileMenu}
              positioningElement={userButtonRef}
            />
          )}
        </HeaderLayout>
      </FocusOn>
    </header>
  );
}

export default Header;
