import { ClickableService, ClickedService, CoachSelection } from '@vrfi/web-components';

import {
  Reservation,
  SalesSessionFragment,
  TrainInfoFragment,
  TrainType,
} from 'backend/types.codegen';

import { SelectedCabin } from 'components/ui/WagonMapModal/PlaceChangeModal/NightCabinController/NightCabinController.utils';
import { UpdatedReservation } from 'components/ui/WagonMapModal/PlaceChangeModal/SeatsController/SeatsController.types';

import { log } from 'logging/log';

import { PurchaseFlowType } from 'types/sales';
import { isDefined, Maybe, shouldNotHappen } from 'types/utils';

import { createDate } from 'utils/date';
import { RefinedLeg } from 'utils/getJourneyLegs';
import { createRelativeUrlTo } from 'utils/route';

import { GoogleTagManagerEvent, GoogleTagManagerEventAction, Seat } from './ga3Types';
import { loadPage } from './loadPage';
import pushToDataLayer from './pushToDataLayer';
import {
  AnalyticsPaymentMethod,
  GA4BeginCheckoutData,
  GA4PurchaseData,
  GA4PurchaseFlowType,
  SeatChangeItem,
} from './types';
import {
  getEcommerceDetails,
  getJourneyDetails,
  getProductItems,
  getPurchaseFlowDetailFromSalesSession,
  getPurchaseFlowType,
  toYesNo,
} from './utils';

export const getAnalyticsPurchaseFlowType = (
  flow: PurchaseFlowType,
  salesSession: SalesSessionFragment,
): GA4PurchaseFlowType => {
  switch (flow) {
    case PurchaseFlowType.SingleTicket: {
      const isSeriesTicketUsage = salesSession.groupJourneys.some(
        (groupJourney) =>
          groupJourney.passengerJourneys?.some(
            (passengerJourney) => passengerJourney.seriesTicketId,
          ) ?? false,
      );

      return isSeriesTicketUsage ? 'USE_SERIES' : 'BUY_SINGLE';
    }
    case PurchaseFlowType.MultiTicket:
      return 'BUY_SERIES';
    case PurchaseFlowType.Meals:
      return 'SELF_SERVICE_CHANGE';
    case PurchaseFlowType.ChangeDeparture:
      return 'TRIP_CHANGE';
    case PurchaseFlowType.ChangeSeat:
      return 'SEAT_CHANGE';
    case PurchaseFlowType.AddVehicles:
      return 'ADD_VEHICLE_CHANGE';
    case PurchaseFlowType.AddBicycles:
      return 'ADD_BICYCLE_CHANGE';
    case PurchaseFlowType.AddExternalBusLeg:
      return 'ADD_EXTERNAL_BUS_LEG_CHANGE';
    case PurchaseFlowType.GroupSales:
    case PurchaseFlowType.GroupSalesMeals:
      return 'GROUP_SALES';
    default:
      return shouldNotHappen(flow);
  }
};

const beginCheckout = (
  salesSession: SalesSessionFragment,
  paymentMethodName: AnalyticsPaymentMethod,
  flow: PurchaseFlowType,
  loggedIn: boolean,
) => {
  try {
    const purchaseFlowType = getAnalyticsPurchaseFlowType(flow, salesSession);
    const journeyDetails = getJourneyDetails(salesSession);
    const ecommerceDetails = getEcommerceDetails(salesSession, purchaseFlowType);

    const data: GA4BeginCheckoutData = {
      loggedin: toYesNo(loggedIn),
      event: 'begin_checkout',
      ...journeyDetails,
      purchase_flow_detail: getPurchaseFlowDetailFromSalesSession(salesSession),
      purchase_flow_type: purchaseFlowType ?? getPurchaseFlowType(salesSession),
      inventory_reservation_id: getReservationIds(salesSession),
      ecommerce: { ...ecommerceDetails, payment_type: paymentMethodName },
    };

    pushToDataLayer(data);
  } catch (e) {
    log(e);
  }
};

const purchase = (
  salesSession: SalesSessionFragment,
  paymentMethodName: AnalyticsPaymentMethod,
  flow: PurchaseFlowType,
  loggedIn: boolean,
) => {
  try {
    const [outboundJourney, returnJourney] = salesSession?.groupJourneys ?? [];
    const seriesTicket = salesSession?.seriesTicket;
    const purchaseFlowType = getAnalyticsPurchaseFlowType(flow, salesSession);

    const data: GA4PurchaseData = {
      loggedin: toYesNo(loggedIn),
      event: 'purchase',
      from_station: outboundJourney ? outboundJourney.departureStation : seriesTicket?.stations[0],
      to_station: outboundJourney ? outboundJourney.arrivalStation : seriesTicket?.stations[1],
      departure_date: outboundJourney
        ? createDate(outboundJourney.departureTime).valueOf()
        : undefined,
      return_date: returnJourney ? createDate(returnJourney.departureTime).valueOf() : undefined,
      purchase_flow_detail: getPurchaseFlowDetailFromSalesSession(salesSession),
      purchase_flow_type: purchaseFlowType ?? getPurchaseFlowType(salesSession),
      inventory_reservation_id: getReservationIds(salesSession),
      ecommerce: {
        currency: 'EUR',
        value: salesSession.amountToPay ? salesSession.amountToPay / 100 : 0,
        payment_type: paymentMethodName,
        transaction_id: salesSession.orderNumber,
        items: getProductItems(salesSession, purchaseFlowType),
      },
    };

    pushToDataLayer(data);
  } catch (e) {
    log(e);
  }
};

const openWagonMap = (salesSession: Maybe<SalesSessionFragment>, leg: RefinedLeg) => {
  if (!salesSession) {
    return;
  }

  const reservations = salesSession.groupJourneys.flatMap(
    (groupJourney) =>
      groupJourney.passengerJourneys?.flatMap((passengerJourney) =>
        passengerJourney.legs
          .filter(({ legKey }) => leg.legKey === legKey)
          .flatMap((leg) => {
            const ticketClass = leg.reservation?.ticketClass;

            if (!leg.reservation || !ticketClass) {
              return [];
            }

            return [{ ...leg.reservation, ticketClass }];
          }),
      ) ?? [],
  );

  const seats = reservations.map(
    (reservation): Seat => ({
      trainType: leg.trainInfo?.trainType as TrainType,
      trainNumber: leg.trainInfo?.trainNumber,
      coachNumber: reservation.wagonNumber ? parseInt(reservation.wagonNumber, 10) : undefined,
      seatNumber: reservation.placeNumber ? parseInt(reservation.placeNumber, 10) : undefined,
      floor: reservation.floor && reservation.floor > 1 ? 'up' : 'down',
      seatType: reservation.ticketClass.toLowerCase(),
      seatChangeActionType: null,
      reference: reservation.reference,
    }),
  );

  pushToDataLayer({
    event: GoogleTagManagerEvent.Wagonmap,
    pagePath: createRelativeUrlTo(window.location),
    timestamp: Date.now(),
    eventAction: GoogleTagManagerEventAction.Open,
    eventLabel: null,
    seats,
  });
};

const closeWagonMap = () => {
  pushToDataLayer({
    event: GoogleTagManagerEvent.Wagonmap,
    pagePath: createRelativeUrlTo(window.location),
    timestamp: Date.now(),
    eventAction: GoogleTagManagerEventAction.Close,
    eventLabel: null,
  });
};

type Cabin = {
  coachNumber: number;
  placeNumbers: number[];
};
type Place = {
  coach: number;
  number: number;
};

const getTrainEventLabel = ({
  place,
  cabin,
  trainInfo,
  wagon,
}: {
  trainInfo: TrainInfoFragment;
  cabin?: Cabin;
  place?: Place;
  wagon?: CoachSelection;
}) => {
  const details =
    (cabin && `${cabin.coachNumber}/${cabin.placeNumbers.join('-')}`) ||
    (place && `${place.coach}/${place.number}`) ||
    (wagon && `${wagon.coach}/${wagon.floor}`) ||
    '';
  const label = trainInfo.commercialLineId || `${trainInfo.trainType}${trainInfo.trainNumber}`;

  return `${trainInfo.trainType}/${label}/${details}`;
};

const seatChangeConfirmed = (
  changedReservations: Reservation[],
  salesSession: SalesSessionFragment,
  leg: RefinedLeg,
  flow?: PurchaseFlowType,
) => {
  const items: SeatChangeItem[] = changedReservations.map((reservation) => ({
    item_id: reservation.reference ?? '',
    quantity: 1,
    item_name: reservation.product ? reservation.product.toLowerCase() : '',
    item_brand: 'VR',
    item_category: 'additional_services/seats',
    price: reservation.price ? reservation.price / 100 : undefined,
    item_list_name: 'wagon_map',
  }));

  const groupJourney = salesSession?.groupJourneys.find(({ passengerJourneys }) =>
    passengerJourneys?.some(({ legs }) => legs.some(({ id }) => leg.legIds.includes(id))),
  );

  pushToDataLayer({
    event: 'select_item',
    from_station: groupJourney?.departureStation,
    to_station: groupJourney?.arrivalStation,
    inventory_reservation_id: changedReservations
      .map((reservation) => reservation.reference)
      .join(', '),
    purchase_flow_detail: getPurchaseFlowDetailFromSalesSession(salesSession), //adult:1
    purchase_flow_type: flow ? getAnalyticsPurchaseFlowType(flow, salesSession) : undefined,
    items: items,
  });
};

const wagonMap = {
  clickSeat: (props: {
    cabin?: Cabin;
    place?: Place;
    wagon?: any;
    trainInfo: TrainInfoFragment;
  }) => {
    pushToDataLayer({
      event: GoogleTagManagerEvent.Wagonmap,
      pagePath: createRelativeUrlTo(window.location),
      timestamp: Date.now(),
      eventAction: GoogleTagManagerEventAction.ClickSeat,
      eventLabel: getTrainEventLabel(props),
    });
  },

  clickCabin: (props: {
    cabin?: Cabin;
    place?: Place;
    wagon?: any;
    trainInfo: TrainInfoFragment;
  }) => {
    pushToDataLayer({
      event: GoogleTagManagerEvent.Wagonmap,
      pagePath: createRelativeUrlTo(window.location),
      timestamp: Date.now(),
      eventAction: GoogleTagManagerEventAction.ClickCabin,
      eventLabel: getTrainEventLabel(props),
    });
  },

  clickServiceIcon: (service: ClickedService) => {
    const keyName = Object.entries(ClickableService).find(([, value]) => service.name === value);

    pushToDataLayer({
      event: GoogleTagManagerEvent.Wagonmap,
      pagePath: createRelativeUrlTo(window.location),
      timestamp: Date.now(),
      eventAction: GoogleTagManagerEventAction.ClickServiceIcon,
      eventLabel: keyName ? keyName[0] : '',
    });
  },

  coachChanged: (props: {
    cabin?: Cabin;
    place?: Place;
    wagon?: any;
    trainInfo: TrainInfoFragment;
  }) => {
    pushToDataLayer({
      event: GoogleTagManagerEvent.Wagonmap,
      pagePath: createRelativeUrlTo(window.location),
      timestamp: Date.now(),
      eventAction: GoogleTagManagerEventAction.CoachChanged,
      eventLabel: getTrainEventLabel(props),
    });
  },
  confirmSeats: (changedReservations: UpdatedReservation[], trainInfo: TrainInfoFragment) => {
    const seats: Seat[] = changedReservations.map(
      ({ placeNumber, wagonNumber, floor, ticketClass, seatChangeType, reference }) => ({
        trainType: trainInfo.trainType,
        trainNumber: trainInfo.trainNumber,
        coachNumber: wagonNumber ? parseInt(wagonNumber, 10) : undefined,
        seatNumber: placeNumber ? parseInt(placeNumber, 10) : undefined,
        floor: floor === 2 ? 'up' : 'down',
        seatType: ticketClass.toLocaleLowerCase(),
        seatChangeActionType: seatChangeType || 'downgrade',
        reference: reference ?? '',
      }),
    );

    pushToDataLayer({
      event: GoogleTagManagerEvent.Wagonmap,
      pagePath: createRelativeUrlTo(window.location),
      timestamp: Date.now(),
      eventAction: GoogleTagManagerEventAction.ConfirmSeatSelection,
      eventLabel: null,
      seats,
    });
  },
  confirmCabins: (operations: SelectedCabin[], trainInfo: TrainInfoFragment) => {
    const cabinChanges: Seat[] = operations.map(
      ({ wagonNumber, compartmentNumber, floor, type }) => ({
        trainType: trainInfo.trainType,
        trainNumber: trainInfo.trainNumber,
        coachNumber: wagonNumber ? parseInt(wagonNumber, 10) : undefined,
        seatNumber: compartmentNumber ? parseInt(compartmentNumber, 10) : undefined,
        floor: floor === 2 ? 'up' : 'down',
        seatType: type,
        seatChangeActionType: null,
      }),
    );

    pushToDataLayer({
      event: GoogleTagManagerEvent.Wagonmap,
      pagePath: createRelativeUrlTo(window.location),
      timestamp: Date.now(),
      eventAction: GoogleTagManagerEventAction.ConfirmCabinSelection,
      eventLabel: null,
      seats: cabinChanges,
    });
  },
};

const getReservationIds = (salesSession: SalesSessionFragment): string =>
  salesSession.groupJourneys
    .flatMap((groupJourney) =>
      groupJourney.passengerJourneys?.flatMap((passengerJourney) =>
        passengerJourney.legs.map((leg) => leg.reservation?.reference),
      ),
    )
    .filter(isDefined)
    .join(', ');

export const salesSessionAboutToExpire = () => {
  pushToDataLayer({
    event: GoogleTagManagerEvent.SalesSessionAboutToExpire,
  });
};

export default {
  openWagonMap,
  wagonMap,
  closeWagonMap,
  beginCheckout,
  purchase,
  loadPage,
  seatChangeConfirmed,
};
