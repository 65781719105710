import dynamic from 'next/dynamic';

import LanguageList from 'components/ui/LanguageList';
import { Col, Container, Row } from 'components/ui/grid';

import { isProduction } from 'utils/envs';

import styles from './MainNavigation.module.css';
import type { AllProps } from './MainNavigation.types';
import Navigation from './Navigation';
import ScrollContainer from './ScrollContainer';

const DebugButton = dynamic(() => import('../../DebugButton'), { ssr: false });

const MainNavigation = ({ id, isOpen, routeLocalizations }: AllProps) => (
  <ScrollContainer isOpen={isOpen} id={id}>
    <div tabIndex={-1}>
      <Container>
        {!isProduction && <DebugButton />}

        <Row>
          <Col className={styles.languageListWrapper}>
            <LanguageList routeLocalizations={routeLocalizations} />
          </Col>
        </Row>
        <Navigation />
      </Container>
    </div>
  </ScrollContainer>
);

export default MainNavigation;
