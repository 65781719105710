import { Page, PageProps } from 'build/types';

import { UserType } from 'types/analytics';

import { getDocumentLength } from 'utils/contentful';
import { formatDateFinnishTZ } from 'utils/date';
import { createRelativeUrlTo } from 'utils/route';

import { isDefined } from 'types/utils';
import { GoogleTagManagerEvent } from './ga3Types';
import pushToDataLayer from './pushToDataLayer';
import { toYesNo } from './utils';

const getContentLength = (page?: Page) => {
  if (!page) {
    return { title: 0, ignress: 0, body: 0, sum: 0 };
  }
  const title = ('title' in page.fields && page.fields.title?.length) || 0;
  const ingress = ('ingress' in page.fields && page.fields.ingress.length) || 0;

  const body =
    ('body' in page.fields && page.fields.body && getDocumentLength(page.fields.body)) || 0;

  return {
    title,
    ingress,
    body,
    sum: title + ingress + body,
  };
};

const toContentAttributes = ({ page, locale }: Pick<PageProps, 'page' | 'locale'>) => {
  const isRoutePage = ('isRoutePage' in page.fields && page.fields.isRoutePage) || false;
  const contentType = isRoutePage ? 'RoutePage' : page.sys.contentType.sys.id;

  return {
    title:
      'pageSlug' in page.fields && page.fields.pageSlug && 'slug' in page.fields.pageSlug.fields
        ? page.fields.pageSlug.fields.slug
        : undefined,
    textContentLength: getContentLength(page),
    contentType,
    topic: 'title' in page.fields ? page.fields.title : undefined,
    author:
      'authors' in page.fields
        ? page.fields.authors
            ?.filter(isDefined)
            .map(({ sys: { id } }) => id)
            .join(', ')
        : undefined,
    publishedDate: 'publicationDate' in page.fields ? page.fields.publicationDate : undefined,
    publishedWeek:
      'publicationDate' in page.fields && page.fields.publicationDate
        ? Number(formatDateFinnishTZ(page.fields.publicationDate, 'I'))
        : undefined,
    goal: 'goal' in page.fields ? page.fields.goal : undefined,
    season: 'season' in page.fields ? page.fields.season : undefined,
    customerSegment: 'customerSegment' in page.fields ? page.fields.customerSegment : undefined,
    pageId: page.sys.id,
    isRoutePage,
    language: locale,
  };
};

type LoadPagePayload = {
  pageProps: Pick<PageProps, 'page' | 'locale'>;
  customerId?: string;
  userType?: UserType;
  webFrontendVersion?: string;
};

export const loadPage = ({
  pageProps,
  customerId,
  userType,
  webFrontendVersion,
}: LoadPagePayload) => {
  if (document.referrer.match(/nets\.eu|nordea\.fi/)) {
    // prevents the sending of document.referrer to GA when user returns to the site from payment provider.
    // This needs to be pushed before page_view event
    pushToDataLayer({
      excludeReferrer: true,
    });
  }
  pushToDataLayer({
    event: GoogleTagManagerEvent.PageView,
    timestamp: Date.now(),
    pagePath: createRelativeUrlTo(window.location),
    loginStatus: toYesNo(customerId),
    releaseVersion: webFrontendVersion ?? undefined,
    content: toContentAttributes(pageProps),
    userId: customerId,
    userRole: userType,
  });
};
