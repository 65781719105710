import { useSearchParams } from 'next/navigation';
import { useMemo } from 'react';

import { FormPages, ProfilePages } from 'build/pages/types';

import { StepKey } from 'components/structural/PurchaseFlowSteps/types';

import { useSiteData } from 'contexts/siteData';

import { useCurrentPurchaseFlowStep } from 'hooks/purchaseFlow/useCurrentPurchaseFlowStep';
import { CancelFlowSource } from 'hooks/useCancellationQueryParams';
import { useCurrentFormPage } from 'hooks/useCurrentFormPage';
import { usePageSlug } from 'hooks/usePages';

import {
  GroupSalesPurchaseFlowStepEnum,
  MultiTicketPurchaseFlowStepEnum,
  PurchaseFlowStepEnum,
} from 'types/purchaseFlow';

import { UrlParts } from 'utils/route';

import { useIsOnTripRelatedPage } from './useIsOnTripRelatedPage';

const PURCHASE_FLOW_STEPS_TO_IGNORE: StepKey[] = [
  PurchaseFlowStepEnum.OutboundSearchResults,
  MultiTicketPurchaseFlowStepEnum.StartingPage,
  MultiTicketPurchaseFlowStepEnum.OffersPage,
];

const PURCHASE_FLOW_STEPS: StepKey[] = [
  ...Object.values(PurchaseFlowStepEnum),
  ...Object.values(MultiTicketPurchaseFlowStepEnum),
  ...Object.values(GroupSalesPurchaseFlowStepEnum),
].filter((step) => !PURCHASE_FLOW_STEPS_TO_IGNORE.includes(step));

const DO_NOT_SHOW_WARNING_ON: StepKey[] = [
  PurchaseFlowStepEnum.PaymentConfirmation,
  MultiTicketPurchaseFlowStepEnum.PaymentConfirmationPage,
];

export const useOnRoleChange = (): { navigateTo?: UrlParts; showWarning: boolean } => {
  const { landingPage } = useSiteData();
  const searchParams = useSearchParams();

  const journeysLinkTarget = usePageSlug(ProfilePages.MY_JOURNEYS_PAGE);

  const currentPurchaseFlowStep = useCurrentPurchaseFlowStep();

  const isInTripRelatedPage = useIsOnTripRelatedPage();

  const isInPurchaseFlow =
    !!currentPurchaseFlowStep && PURCHASE_FLOW_STEPS.includes(currentPurchaseFlowStep);

  const isRedeemingSeriesTickets =
    currentPurchaseFlowStep === PurchaseFlowStepEnum.OutboundSearchResults &&
    searchParams.has('multiTicketId');

  const formPage = useCurrentFormPage();

  const isOnTripCancellationPage =
    formPage === FormPages.TICKET_CANCELLATION_FORM &&
    searchParams.get('source') === CancelFlowSource.profile;

  const navigateTo =
    isInTripRelatedPage || isOnTripCancellationPage
      ? journeysLinkTarget
      : isRedeemingSeriesTickets || isInPurchaseFlow || formPage
      ? landingPage
      : undefined;

  const showWarning = isInPurchaseFlow && !DO_NOT_SHOW_WARNING_ON.includes(currentPurchaseFlowStep);

  return useMemo(
    () => ({
      navigateTo: navigateTo ? { pathname: navigateTo } : undefined,
      showWarning,
    }),
    [navigateTo, showWarning],
  );
};
