import type { TrainType } from 'backend/types.codegen';

export enum GoogleTagManagerEvent {
  PageView = 'pageView',
  Wagonmap = 'wagonmap',
  SalesSessionAboutToExpire = 'optimize.activate.20minBooking',
}

export enum GoogleTagManagerEventAction {
  Open = 'open',
  Close = 'close',
  CoachChanged = 'coach changed',
  ClickSeat = 'click seat',
  ClickServiceIcon = 'click service icon',
  ConfirmSeatSelection = 'confirm seat selection',
  ConfirmCabinSelection = 'confirm cabin selection',
  ClickCabin = 'click cabin',
}

export interface Seat {
  trainType: TrainType;
  trainNumber?: string | null;
  coachNumber?: number;
  seatNumber?: number;
  floor?: 'up' | 'down';
  /** Lowercase variant of SeatClass */
  seatType: string;
  seatChangeActionType: 'upgrade' | 'downgrade' | 'seatchange' | null;
  reference?: string | null;
}
