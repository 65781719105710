import { useIntl } from 'react-intl';

import LocalizedMessage from 'components/i18n/LocalizedMessage';
import Link from 'components/ui/Link';

import { GenericMessage } from 'constants/i18n.messages';

import { useCurrentPurchaseFlowStep } from 'hooks/purchaseFlow/useCurrentPurchaseFlowStep';

import { PurchaseFlowStepEnum } from 'types/purchaseFlow';

import styles from './JumpToPurchaseFlow.module.css';

const ticketStartingPage = {
  fi: '/junaliput?ref=header#matkahaku',
  en: '/en/train-tickets?ref=header#trip-search',
  sv: '/sv/tagbiljetter?ref=header#resesokning',
} as const;

const useOnForbiddenPage = () => {
  const currentPurchaseFlowStep = useCurrentPurchaseFlowStep();
  const inPurchaseFlow = !!currentPurchaseFlowStep;
  const isPaymentConfirmationPage =
    currentPurchaseFlowStep === PurchaseFlowStepEnum.PaymentConfirmation;
  return inPurchaseFlow && !isPaymentConfirmationPage;
};

export const JumpToPurchaseFlow = () => {
  const { locale } = useIntl();
  const onForbiddenPage = useOnForbiddenPage();

  if (onForbiddenPage) {
    return null;
  }

  const href = ticketStartingPage[locale || 'fi'];

  return (
    <Link
      className={styles.item}
      data-testid="buy-ticket-link"
      data-text="16 semibold navLink"
      href={href}
      style={{ color: 'black' }}
    >
      <LocalizedMessage id={GenericMessage.BUY_TICKET} />
    </Link>
  );
};
