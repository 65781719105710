import { useEffect, useRef } from 'react';

import { ColorEnum, Toggletip } from '@vrfi/web-components';
import { Box } from '@vrfi/web-components/react/reactComponents';

import { Text } from 'components/cssModules';
import LocalizedMessage from 'components/i18n/LocalizedMessage';
import { UserDefaultIcon } from 'components/ui/Icons';

import { LoginMessage } from 'constants/i18n.messages';

import { useIsAuthenticated } from 'hooks/useIsAuthenticated';
import { getStoredState, setStoredState, useStoredState } from 'hooks/useStoredState';

import { StorageKey } from 'types/stateStore';

import { daysToMs, secondsToMs } from 'utils/ms';

import { polyfillDialog } from 'components/ui/Modal/Modal.polyfill';
import styles from './LoginTooltip.module.css';

const SHOW_INTERVAL = daysToMs(5);
const HIDE_TIMEOUT = secondsToMs(4);

export const LoginTooltip = () => {
  const ref = useRef<HTMLDialogElement>(null);
  const [previouslyLoggedIn] = useStoredState(StorageKey.PreviouslyLoggedIn);
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated) {
      return;
    }

    const now = Date.now();
    const tooltipShownOn = getStoredState(StorageKey.LoginTooltipShown);

    if (tooltipShownOn && now < tooltipShownOn + SHOW_INTERVAL) {
      return;
    }

    polyfillDialog(ref.current, (dialog) => dialog.show());
    setStoredState(StorageKey.LoginTooltipShown, now);

    // Close tooltip after 4s
    const timeout = setTimeout(() => ref.current?.close(), HIDE_TIMEOUT);

    return () => clearTimeout(timeout);
  }, [isAuthenticated]);

  if (isAuthenticated) {
    return null;
  }

  return (
    <div className={styles.tooltip} aria-hidden tabIndex={-1}>
      <Toggletip
        ref={ref}
        color="white"
        position="bottom"
        closeOnInteractionOutside="click"
        data-testid="login-tooltip"
        shadow
        anchorClass={styles.anchor}
      >
        <Box as="div" spacing="s08Row">
          <UserDefaultIcon color={ColorEnum.green08} />
          <Text
            paragraph={16}
            semibold
            className={styles.tooltipContent}
            data-testid={previouslyLoggedIn ? 'returning-user' : 'new-user'}
          >
            <LocalizedMessage
              id={previouslyLoggedIn ? LoginMessage.TOOLTIP_LOGIN : LoginMessage.TOOLTIP_REGISTER}
            />
          </Text>
        </Box>
      </Toggletip>
    </div>
  );
};
