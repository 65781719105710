import classNames from 'classnames';

import { Box, Text } from 'components/cssModules';
import { Radio } from 'components/cssModules/form';
import LocalizedMessage from 'components/i18n/LocalizedMessage';

import { ProfileSelectionMessage } from 'constants/i18n.messages';

import CustomerAvatar from '../Header/ProfileNavigation/CustomerAvatar';
import InitialsAvatar from '../InitialsAvatar';
import styles from './ProfileSelectOption.module.css';

type Props = {
  id: string;
  name: string;
  text: string;
  companyId?: string;
  type: 'customer' | 'company' | 'travelAgent';
  checked: boolean;
  canPurchase?: boolean;
  truncate?: boolean;
  onChange: () => void;
};

export const ProfileSelectOption = ({
  id,
  name,
  text,
  companyId,
  type,
  checked,
  canPurchase = false,
  truncate = false,
  onChange,
}: Props) => {
  const avatarColor = type === 'company' ? 'blue' : 'yellow';

  const labelId = `label_${id}`;
  const descriptionId = `description_${id}`;

  return (
    <Box as={'div'} className={styles.company}>
      <Radio
        id={id}
        name={name}
        aria-labelledby={labelId}
        aria-describedby={descriptionId}
        data-testid={`profile_select_${id}`}
        className={styles.label}
        onChange={onChange}
        checked={checked}
        data-type={type}
        data-company-id={companyId}
      >
        <div className={styles.textContent}>
          <div>
            {type === 'customer' ? (
              <CustomerAvatar />
            ) : (
              <InitialsAvatar text={text} color={avatarColor} />
            )}
          </div>
          <div>
            <Text
              as="div"
              id={labelId}
              size={16}
              className={classNames({
                [styles.threeLines]: truncate,
              })}
              semibold
            >
              {text}
            </Text>
            <Text as="div" id={descriptionId} size={16} grey17>
              {companyId ? (
                <LocalizedMessage id={ProfileSelectionMessage.COMPANY}>
                  {(label) => `${label} ${companyId}`}
                </LocalizedMessage>
              ) : (
                <LocalizedMessage
                  id={
                    type === 'customer'
                      ? ProfileSelectionMessage.CUSTOMER_PROFILE
                      : type === 'company'
                      ? ProfileSelectionMessage.COMPANY_PROFILE
                      : ProfileSelectionMessage.TRAVEL_AGENT_PROFILE
                  }
                />
              )}
            </Text>
            {!canPurchase && (
              <Text size={16} grey17>
                <LocalizedMessage id={ProfileSelectionMessage.NO_PURCHASE_RIGHT} />
              </Text>
            )}
          </div>
        </div>
      </Radio>
    </Box>
  );
};
