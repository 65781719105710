import pushToDataLayer from 'analytics/pushToDataLayer';

import { createRelativeUrlTo } from 'utils/route';

export const pushLogoutEvent = () => {
  pushToDataLayer({
    event: 'userAction',
    pagePath: createRelativeUrlTo(window.location),
    timestamp: Date.now(),
    eventCategory: 'user account',
    eventAction: 'logout',
    eventLabel: null,
    eventNonInteraction: false,
  });
};
