import { ColorEnum } from '@vrfi/web-components';

import { Text } from 'components/cssModules';
import { ChevronRightIcon } from 'components/ui/Icons';
import Link from 'components/ui/Link';
import { Col, Row } from 'components/ui/grid';

import { useSiteData } from 'contexts/siteData';

import LocalizedMessage from 'components/i18n/LocalizedMessage';
import { AccessibilityMessage } from 'constants/i18n.messages';
import { usePathname } from 'next/navigation';
import { isDefined } from 'types/utils';
import styles from './Navigation.module.css';

const Navigation = () => {
  const currentPathname = usePathname();
  const categories = useSiteData().navigation;

  if (!categories) return null;

  return (
    <nav aria-labelledby="main-nav-label">
      <Row>
        <span hidden id="main-nav-label">
          <LocalizedMessage id={AccessibilityMessage.NAV_MAIN_NAV_TITLE} />
        </span>
      </Row>
      <Row>
        {categories.map((cat, i) => {
          if (!cat) {
            return null;
          }

          const link = cat.headingLink;

          const catId = `menu-cat-${i}`;

          return (
            <Col md={4} key={i} data-testid="Navigation__category" className={styles.col}>
              <Text as="h2" title4 data-testid="Navigation__category-title">
                {link ? (
                  <Link href={link} className={styles.categoryLink}>
                    {cat.menuHeading}
                    <ChevronRightIcon size={2} color={ColorEnum.green10} />
                  </Link>
                ) : (
                  cat.menuHeading
                )}
              </Text>
              {cat.subHeading && (
                <Text paragraph={14} data-testid="Navigation__category-sub-heading">
                  {cat.subHeading}
                </Text>
              )}
              {!link && cat.menuCategoryPages && (
                <ul id={catId} className={styles.links}>
                  {cat.menuCategoryPages.filter(isDefined).map((page, index) => (
                    <li key={index}>
                      <Link
                        href={page.pathname}
                        aria-current={currentPathname === page.pathname ? 'page' : undefined}
                      >
                        {page.navigationTitle || page.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </Col>
          );
        })}
      </Row>
    </nav>
  );
};

export default Navigation;
