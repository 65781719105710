import useSWR from 'swr';

import { LOCALE } from 'constants/i18n';

import { fetcher } from 'utils/fetcher';

type LocationScope = 'full' | 'contentPages' | 'purchaseFunnel';

type UserType = {
  isCorporate: boolean;
};

const userTypeScope: Array<keyof UserType> = ['isCorporate'];
type UserTypeScope = keyof Pick<UserType, (typeof userTypeScope)[number]>;

type WebScope = LocationScope | UserTypeScope;

export const isUserTypeScope = (scope: WebScope): scope is UserTypeScope =>
  (userTypeScope as string[]).includes(scope);

export interface Notification {
  id: string;
  title: Record<LOCALE, string>;
  type: 'Alert' | 'MajorNotice' | 'MinorNotice';
  body: Record<LOCALE, string>;
  scope: WebScope[];
  webUrls?: string[];
  createdAt: string;
  updatedAt: string;
  activeFrom?: string;
  activeUntil?: string;
  revision?: number;
}

export const useGetNotifications = () => {
  return useSWR<Notification[]>('/api/latest/notifications/web', {
    refreshInterval: 60000,
    fetcher,
  });
};
