import classNames from 'classnames';
import { ReactNode } from 'react';

import styles from './ScrollContainer.module.css';

interface Props {
  children: ReactNode;
  className?: string;
  isOpen: boolean;
  id?: string;
}

function ScrollContainer({ children, className, isOpen, id }: Props) {
  return (
    <div id={id} className={classNames(className, styles.container, { [styles.open]: isOpen })}>
      {children}
    </div>
  );
}

export default ScrollContainer;
