'use client';
import { useSiteData } from 'contexts/siteData';

export const Versions = () => {
  const { commit, built } = useSiteData().metadata;

  return (
    <div>
      {commit} @ {built}
    </div>
  );
};
