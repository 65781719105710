import { useMemo } from 'react';

import { JwtCompanyRole } from 'backend/types.codegen';

import { CompanyProfile, useGetCompanyRoles } from './useGetCompanyRoles';

export const useAllCompanyProfiles = (): {
  loading?: boolean;
  companyProfiles: CompanyProfile[] | undefined;
} => {
  const { companyRoles } = useGetCompanyRoles();

  return useMemo(
    () => ({
      companyProfiles: (companyRoles ?? []).flatMap<CompanyProfile>(({ name, name2, roles }) => {
        const roleForId = roles.find((role) =>
          [JwtCompanyRole.ForteCorporateUser, JwtCompanyRole.ForteTravelAgencyClerk].includes(
            role.role,
          ),
        );

        if (!roleForId) {
          return [];
        }

        return {
          id: roleForId.id,
          name,
          name2: name2 ?? undefined,
          canPurchase: roleForId.canPurchase ?? false,
          isAdmin: roles.some(({ role }) =>
            [JwtCompanyRole.B2BAdmin, JwtCompanyRole.TravelAgentAdmin].includes(role),
          ),
          roles: roles.map(({ role }) => role),
        };
      }),
    }),
    [companyRoles],
  );
};
