'use client';
import { useEffect, useState } from 'react';

import { useLocation } from 'utils/navigation';

import { removeNotifications } from 'state/notifications';

import { ExpirationNotification } from './ExpirationNotification/ExpirationNotification';
import { InternalNotifications } from './InternalNotifications';
import { Notification } from './Notification';
import styles from './Notifications.module.css';
import { NotificationType } from './types';
import { useExternalNotifications } from './useNotifications';

function Notifications() {
  const loc = useLocation();
  const [path, setPath] = useState(loc.pathname);
  const { externalNotifications, setExternalNotificationRead } = useExternalNotifications();

  useEffect(() => {
    if (loc.pathname !== path) {
      removeNotifications({ path, clearShowOnceOnly: true });
      setPath(loc.pathname);
    }
  }, [loc.pathname, path]);

  return (
    <div className={styles.container}>
      {externalNotifications.length > 0 &&
        externalNotifications.map((notice) => (
          <Notification
            key={notice.id}
            {...notice}
            type={NotificationType[notice.type]}
            setNotificationRead={setExternalNotificationRead}
          />
        ))}

      <InternalNotifications />

      <ExpirationNotification />
    </div>
  );
}

export default Notifications;
