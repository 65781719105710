import pushToDataLayer from 'analytics/pushToDataLayer';

import { LOCALE } from 'constants/i18n';

import { createRelativeUrlTo } from 'utils/route';

export const pushChangeLanguageEvent = (newLocale: LOCALE) => {
  pushToDataLayer({
    event: 'userAction',
    pagePath: createRelativeUrlTo(window.location),
    timestamp: Date.now(),
    eventCategory: 'language version',
    eventAction: 'change',
    eventLabel: newLocale,
    eventNonInteraction: false,
  });
};
