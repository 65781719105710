import * as Yup from 'yup';

import { PaymentMethodName, PriceElementScope } from 'backend/types.codegen';

const paymentInfoSchema = Yup.object({
  saleId: Yup.string().required(),
  search: Yup.string(),
  paymentId: Yup.string(),
  emails: Yup.array(Yup.string().required()).required(),
  timestamp: Yup.string()
    .matches(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/) // for some weird reason .datetime() validator does not work on built app 🤷
    .required(),
  paymentMethod: Yup.string().oneOf(Object.values(PaymentMethodName)).required(),
  scope: Yup.array(Yup.string().oneOf(Object.values(PriceElementScope)).required()),
  journeyKey: Yup.string(),
  placeId: Yup.string(),
  destinations: Yup.array(Yup.string().required()),
});

export const paymentStoreSchema = Yup.array(paymentInfoSchema).required();

export type PaymentInfo = Yup.InferType<typeof paymentInfoSchema>;

export const PAYMENT_STORE_KEY = 'paymentStore';
