import dynamic from 'next/dynamic';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { useLogoutV2 } from 'backend/hooks/useLogout';

import { FormPages } from 'build/pages/types';

import { useRouteState } from 'components/structural/Route';

import { useModal } from 'contexts/modal';
import { useSiteData } from 'contexts/siteData';

import { useSalesSessionUrlParam } from 'hooks/salesSession/useSalesSessionUrlParam';
import { useIdentity } from 'hooks/useIdentity';
import { useIsFormsTicketCancellationFlowPage } from 'hooks/useIsFormsTicketCancellationFlowPage';
import { usePageSlug } from 'hooks/usePages';
import { useVariable } from 'hooks/useVariable';

import { ciamLogoutReturnTo, useNavigate } from 'utils/navigation';

const LogoutModal = dynamic(() => import('../../LogoutModal'), { ssr: false });

export const useDoLogout = (onLogout?: () => void) => {
  const { locale } = useIntl();
  const { landingPage } = useSiteData();
  const [identity] = useIdentity();
  const navigate = useNavigate();
  const [logout, { loading }] = useLogoutV2();
  const [{ redirectToLandingPageOnLogout }] = useRouteState();
  const ticketCancellationPage = usePageSlug(FormPages.TICKET_CANCELLATION_FORM);
  const isFormsTicketCancellationFlow = useIsFormsTicketCancellationFlowPage();
  const { saleId } = useSalesSessionUrlParam();
  const { showModal } = useModal();
  const onLogoutRef = useVariable(onLogout);

  const doLogout = useCallback(async () => {
    if (saleId) {
      const confirmed = await showModal<boolean>((onClose) => <LogoutModal onClose={onClose} />);
      if (!confirmed) {
        return;
      }
    }

    let returnToUrl = window.location.href;
    if (redirectToLandingPageOnLogout || saleId) {
      returnToUrl = landingPage;
    } else if (isFormsTicketCancellationFlow) {
      // If user logouts in ticket cancellation flow, which started from forms, let's redirect to start of the form
      returnToUrl = ticketCancellationPage;
    }

    if (identity?.isCiamUser) {
      await navigate(ciamLogoutReturnTo(locale, returnToUrl, true));
      return;
    }

    await logout({ variables: { redirectUrl: '' } });

    await navigate(returnToUrl);

    onLogoutRef.current?.();
  }, [
    identity?.isCiamUser,
    isFormsTicketCancellationFlow,
    landingPage,
    locale,
    logout,
    navigate,
    onLogoutRef,
    redirectToLandingPageOnLogout,
    saleId,
    showModal,
    ticketCancellationPage,
  ]);

  return {
    doLogout,
    loading,
  };
};
