'use client';
import classNames from 'classnames';
import { useCallback, useState } from 'react';

import { marginTrimBlock } from '@vrfi/web-components/css/utils.module.css';

import { pushConsentSettingsEvent } from 'analytics/userActions/pushConsentSettingsEvent';
import { pushCookieConsentClickEvent } from 'analytics/userActions/pushCookieConsentClickEvent';

import { Button } from '@vrfi/web-components';
import { Box, Text } from 'components/cssModules';
import LocalizedMessage from 'components/i18n/LocalizedMessage';
import LanguageList from 'components/ui/LanguageList';

import { CookieConsentMessage } from 'constants/i18n.messages';

import { useSiteData } from 'contexts/siteData';

import { useIsMounted } from 'contexts/isMounted';
import { useCookieConsent } from 'hooks/useCookieConsent';

import { margin } from 'styles/compLib';

import { LOCALE } from 'constants/i18n';
import { Modal } from '../Modal/Modal';
import Overlay from '../Modal/Overlay';
import { ModalFocusHandler } from '../ModalFocusHandler';
import { Container } from '../grid';
import styles from './CookieConsent.module.css';
import CookieConsentSettings from './CookieConsentSettings';

type Props = { pageId: string; routeLocalizations: Record<LOCALE, string> };

export function CookieConsent({ pageId, routeLocalizations }: Props) {
  const { cookiePolicy } = useSiteData().pages;
  const showCookieConsent = pageId !== cookiePolicy.id;
  const [consent, setConsent] = useCookieConsent();
  const [isModalOpen, setModalOpen] = useState(false);
  /** We need to delay the rendering for the focus lock to work correctly. */
  const isHydrated = useIsMounted();

  const acceptAllCookies = useCallback(() => {
    const levels = {
      level1: true,
      level2: true,
      level3: true,
      level4: true,
    };
    setConsent({
      consented: true,
      levels,
    });
    pushConsentSettingsEvent(levels);
    pushCookieConsentClickEvent('AcceptAll');
  }, [setConsent]);

  const acceptNecessaryCookies = useCallback(() => {
    const levels = {
      level1: true,
      level2: false,
      level3: false,
      level4: false,
    };
    setConsent({
      consented: true,
      levels,
    });
    pushConsentSettingsEvent(levels);
    pushCookieConsentClickEvent('NecessaryOnly');
  }, [setConsent]);

  const openSettings = useCallback(() => {
    setModalOpen(true);
    pushCookieConsentClickEvent('AdjustSettings');
  }, []);

  const closeSettings = useCallback(() => {
    setModalOpen(false);
    pushCookieConsentClickEvent('CloseButton');
  }, []);

  if (!isHydrated || consent.consented || !showCookieConsent) {
    return null;
  }

  if (isModalOpen) {
    return (
      <Modal
        data-testid="settings-modal"
        onClose={closeSettings}
        headerContent={<LocalizedMessage id={CookieConsentMessage.SETTINGS_TITLE} />}
        heroContent={
          <div className={margin.s16Bottom}>
            <LanguageList routeLocalizations={routeLocalizations} />
          </div>
        }
        skipCookieConsentCheck
      >
        {() => <CookieConsentSettings isInModal />}
      </Modal>
    );
  }

  return (
    <ModalFocusHandler>
      <Overlay moreTranslucent>
        <Box
          as="div"
          color="white"
          spacing="s00Column"
          variant="card"
          className={styles.modal}
          data-testid="cookie-consent-modal"
        >
          <Container className={styles.container} data-testid="cookie-consent">
            <div className={styles.languages}>
              <LanguageList routeLocalizations={routeLocalizations} />
            </div>

            <Text as="h2" title5 className={styles.header}>
              <LocalizedMessage id={CookieConsentMessage.SETTINGS_TITLE} />
            </Text>

            <div className={styles.content}>
              <div className={classNames(styles.contentScroller, marginTrimBlock)}>
                <p>
                  <strong>
                    <LocalizedMessage id={CookieConsentMessage.MESSAGE_HIGHLIGHTED} />
                  </strong>{' '}
                  <LocalizedMessage id={CookieConsentMessage.PRIMARY_CONTENT} />{' '}
                  <strong>
                    <a
                      href={cookiePolicy.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      data-testid="cookie-policy-link"
                      className={styles.link}
                    >
                      <LocalizedMessage id={CookieConsentMessage.READ_MORE} />
                    </a>
                  </strong>
                </p>

                <Text paragraph={16}>
                  <LocalizedMessage id={CookieConsentMessage.SECONDARY_CONTENT} />
                </Text>
              </div>
            </div>

            <Box as="div" spacing="s16Column" className={styles.buttons}>
              <Button onClick={acceptAllCookies} data-testid="accept" stretch>
                <LocalizedMessage id={CookieConsentMessage.BUTTON_ACCEPT_ALL} />
              </Button>
              <Button grey30 onClick={openSettings} data-testid="settings" stretch>
                <LocalizedMessage id={CookieConsentMessage.CUSTOMIZE_SETTINGS} />
              </Button>
              <Button grey30 onClick={acceptNecessaryCookies} data-testid="necessary" stretch>
                <LocalizedMessage id={CookieConsentMessage.CONTINUE_WITH_NECESSARY} />
              </Button>
            </Box>
          </Container>
        </Box>
      </Overlay>
    </ModalFocusHandler>
  );
}
