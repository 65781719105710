import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { removeLoginParams, useLocation } from 'utils/navigation';

export const useCiamLoginHref = (action?: 'signup') => {
  const { locale } = useIntl();
  const location = useLocation();

  return useMemo(() => {
    const returnToUrl = encodeURIComponent(
      removeLoginParams(`${location.pathname}${location.search}${location.hash}`),
    );

    return (
      `/ciam-init-login?locale=${locale}&returnToUrl=${returnToUrl}` +
      (action ? `&action=${action}` : '')
    );
  }, [action, locale, location.hash, location.pathname, location.search]);
};
