import classNames from 'classnames';
import { ReactNode, SyntheticEvent } from 'react';

import styles from './Overlay.module.css';

const stop = (e: SyntheticEvent) => e.stopPropagation();

const stopPropagationEvents = {
  onClick: stop,
  onContextMenu: stop,
  onDoubleClick: stop,
  onDrag: stop,
  onDragEnd: stop,
  onDragEnter: stop,
  onDragExit: stop,
  onDragLeave: stop,
  onDragOver: stop,
  onDragStart: stop,
  onDrop: stop,
  onMouseDown: stop,
  onMouseEnter: stop,
  onMouseLeave: stop,
  onMouseMove: stop,
  onMouseOver: stop,
  onMouseOut: stop,
  onMouseUp: stop,
  onKeyDown: stop,
  onKeyPress: stop,
  onKeyUp: stop,
  onFocus: stop,
  onBlur: stop,
  onChange: stop,
  onInput: stop,
  onInvalid: stop,
  onSubmit: stop,
};

export const Overlay = ({
  moreTranslucent,
  children,
}: {
  moreTranslucent?: boolean;
  children: ReactNode;
}) => (
  <div
    className={classNames(styles.overlay, { [styles.moreTranslucent]: moreTranslucent })}
    {...stopPropagationEvents}
  >
    {children}
  </div>
);
