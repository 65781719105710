import { ReactNode } from 'react';

import { ColorEnum, Grid } from '@vrfi/web-components';

import DynamicIconLoad from 'components/content/DynamicIconLoad';
import { Box, Text } from 'components/cssModules';
import LocalizedMessage from 'components/i18n/LocalizedMessage';
import Link from 'components/ui/Link';
import StoreBadge from 'components/ui/StoreBadge';
import { Container } from 'components/ui/grid';

import { FooterMessage } from 'constants/i18n.messages';

import { useSiteData } from 'contexts/siteData';

import styles from './ContentfulSiteFooter.module.css';
import { EmployeeLoginLink } from './EmployeeLoginLink';

interface LinkColumnProps {
  links?: Array<{ title: string; url: string }>;
  children?: ReactNode;
}

const LinkColumn = ({ links, children }: LinkColumnProps) => (
  <ul data-grid="" data-gap="y08" role="list">
    {links?.map((link, i) => (
      <li key={i}>
        <Link className={styles.footerLink} href={link.url}>
          {link.title}
        </Link>
      </li>
    ))}
    {children}
  </ul>
);

export const ContentfulSiteFooter = () => {
  const footer = useSiteData().footer;

  if (!footer) {
    return null;
  }

  return (
    <div data-testid="siteFooter" data-links-appearance="navLink">
      {footer.appStoreLink && footer.playStoreLink && (
        <Box as={Container} className={styles.appLinkContainer}>
          <Box
            as="div"
            spacing="s16Column"
            stripeCount={16}
            stripeColor="white"
            stripeSize={1}
            variant="stripeBox"
          >
            <Text title4 white as="h2">
              <LocalizedMessage id={FooterMessage.APP_STORE_LINKS} />
            </Text>

            <div data-box="wrap" data-gap="x16 y08">
              <Link
                data-testid="appLink"
                href={footer.appStoreLink}
                target="_blank"
                rel="noopener noreferrer"
                hideExternalLinkIcon
              >
                <StoreBadge badge="AppStore" />
              </Link>

              <Link
                data-testid="appLink"
                href={footer.playStoreLink}
                target="_blank"
                rel="noopener noreferrer"
                hideExternalLinkIcon
              >
                <StoreBadge badge="GooglePlay" />
              </Link>
            </div>
          </Box>
        </Box>
      )}

      <div className={styles.mainLinkContainer}>
        <Container>
          <Grid data-box="alignStart" itemMinWidth="13rem" gaps="x00 y24">
            <LinkColumn links={footer.linkGroup1} />
            <LinkColumn links={footer.linkGroup2} />
            <LinkColumn links={footer.linkGroup3}>
              <li className={styles.columnListItem}>
                <EmployeeLoginLink />
              </li>
            </LinkColumn>
          </Grid>
        </Container>
      </div>

      {footer.socialMediaLinks && (
        <Container className={styles.socialMediaLinkContainer}>
          <ul className={styles.socialMedialLinkList}>
            {footer.socialMediaLinks.map((link) => (
              <li key={link.title} className={styles.socialMedialLinkListItem}>
                <Link
                  data-box="wrap alignCenter"
                  data-gap="s16"
                  href={link.url}
                  aria-label={link.title}
                  hideExternalLinkIcon
                >
                  <DynamicIconLoad icon={link.icon} color={ColorEnum.white} />

                  <span className={styles.hideToXS} aria-hidden="true">
                    {link.title}
                  </span>
                </Link>
              </li>
            ))}
          </ul>
        </Container>
      )}
    </div>
  );
};
