import { useApolloClient, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { useCallback } from 'react';

import { pushLogoutEvent } from 'analytics/userActions/pushLogoutEvent';

import { LogoutV2Mutation, LogoutV2MutationVariables } from 'backend/types.codegen';

import { useCrossTabMessaging } from 'hooks/useCrossTabMessaging';
import { clearStoredState } from 'hooks/useStoredState';

import { StorageKey } from 'types/stateStore';

import { removeSelectedRole } from 'utils/cookie';
import { clearPaymentInfos } from 'utils/paymentStore';

const useCleanUpOnLogout = () => {
  const client = useApolloClient();

  return useCallback(async () => {
    // resetStore purges cache and refetches queries
    await client.resetStore();
    clearPaymentInfos();
    clearStoredState(StorageKey.UserIdentity);
    pushLogoutEvent();
    removeSelectedRole();
  }, [client]);
};

const mutationV2 = gql`
  mutation logoutV2($redirectUrl: String!) {
    logoutV2(redirectUrl: $redirectUrl)
  }
`;

export const useLogoutV2 = () => {
  const { sendCrossTabMessage } = useCrossTabMessaging();
  const cleanup = useCleanUpOnLogout();
  return useMutation<LogoutV2Mutation, LogoutV2MutationVariables>(mutationV2, {
    onCompleted: () => {
      cleanup();
      sendCrossTabMessage('loggedOut');
    },
  });
};
