import { VehicleType } from 'backend/types.codegen';

import { Passenger } from 'hooks/useSetSearchParameters';

import { Reservation } from './getJourneyLegs';

export type AdditionalServices = {
  bicycles: number;
  pets: number;
  wheelchairs: number;
  cars: number;
  motorcycles: number;
  serviceCount: number;
  assistants: number; // assistants are a special case they don't count towards the service count.
};

export const getAdditionalServices = (
  passengers: Passenger[] | Reservation[],
): AdditionalServices => {
  let [bicycles, pets, wheelchairs, cars, motorcycles, assistants] = [0, 0, 0, 0, 0, 0];

  passengers.forEach((passenger) => {
    bicycles = bicycles + passenger.bicycles;
    pets = pets + passenger.pets;
    wheelchairs = wheelchairs + (passenger.wheelchair ? 1 : 0);
    if ('type' in passenger) {
      // Passenger has a type, Reservation does not have
      cars = cars + (passenger.vehicle?.vehicleType === VehicleType.Car ? 1 : 0);
      motorcycles =
        motorcycles + (passenger.vehicle?.vehicleType === VehicleType.Motorcycle ? 1 : 0);
      assistants = assistants + (passenger.assistant ? 1 : 0);
    }
  });

  const serviceCount = [bicycles, pets, wheelchairs, cars, motorcycles].reduce(
    (sum, current) => sum + (current > 0 ? 1 : 0),
    0,
  );

  return {
    bicycles,
    pets,
    wheelchairs,
    cars,
    motorcycles,
    serviceCount,
    assistants,
  };
};
