import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

import { ReleaseSaleMutation, ReleaseSaleMutationVariables } from 'backend/types.codegen';

const RELEASE_SALE = gql`
  mutation releaseSale($saleId: String!) {
    releaseSale(saleId: $saleId)
  }
`;

export const useReleaseSale = () => {
  return useMutation<ReleaseSaleMutation, ReleaseSaleMutationVariables>(RELEASE_SALE, {
    errorPolicy: 'ignore',
    update(cache, _, options) {
      const { saleId } = options.variables ?? {};
      if (!saleId) {
        return;
      }

      const id = cache.identify({ id: saleId, __typename: 'SalesSession' });

      cache.evict({ id });
    },
  });
};
