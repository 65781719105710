import endOfHour from 'date-fns/endOfHour';
import endOfMinute from 'date-fns/endOfMinute';
import { useEffect, useState } from 'react';

import { createDate } from 'utils/date';

export const useNow = (updateInterval: 'minute' | 'hour' = 'minute') => {
  const [now, setNow] = useState(createDate);

  useEffect(() => {
    let timeout: number;

    const update = () => {
      setNow(createDate());
      schedule();
    };

    const schedule = () => {
      const now = createDate();
      const nextUpdate = updateInterval === 'minute' ? endOfMinute(now) : endOfHour(now);
      const delay = nextUpdate.getTime() - now.getTime();
      timeout = window.setTimeout(update, delay);
    };

    schedule();

    return () => {
      window.clearTimeout(timeout);
    };
  }, [updateInterval]);

  return now;
};
