import { useCiamLoginHref } from 'components/ui/CiamLogin/useCiamLoginHref';
import { useEffect, useRef } from 'react';
import { onCachedBack, useHasLoginParam, useRemoveLoginParam } from 'utils/navigation';
import { useCookieConsent } from './useCookieConsent';

export const useLoginParamHandler = () => {
  const [{ consented }] = useCookieConsent();
  const ciamLoginHref = useCiamLoginHref();
  const hasLoginParam = useHasLoginParam();
  const removeLoginParam = useRemoveLoginParam();
  const navigatingToCiamRef = useRef(false);

  useEffect(() => {
    if (consented && hasLoginParam && !navigatingToCiamRef.current) {
      const navigateToCiam = async () => {
        navigatingToCiamRef.current = true;
        await removeLoginParam();

        onCachedBack(() => {
          navigatingToCiamRef.current = false;
        });

        window.location.href = ciamLoginHref;
      };

      navigateToCiam();
    }
  }, [ciamLoginHref, consented, hasLoginParam, removeLoginParam]);
};
