'use client';
import { useClaimSalesSessionAfterLogin } from 'hooks/salesSession/useClaimSalesSessionAfterLogin';
import { useReleaseSalesSessionWhenNeeded } from 'hooks/salesSession/useReleaseSalesSessionWhenNeeded';
import { useLoginParamHandler } from 'hooks/useLoginParamHandler';
import { useClearEmailsOutsidePurchaseFlow } from 'state/deliveryEmail';
import { useClearOldPaymentInfos } from 'utils/paymentStore';

// Hooks we want to be inside the Error boundary
export const HookHolder = () => {
  useReleaseSalesSessionWhenNeeded();
  useClaimSalesSessionAfterLogin();
  useClearOldPaymentInfos();
  useClearEmailsOutsidePurchaseFlow();
  useLoginParamHandler();
  return null;
};
