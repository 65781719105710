import { useEffect } from 'react';

import { useVariable } from 'hooks/useVariable';

/**
 * Possible values of KeyboardEvent.key
 * More info: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
 */
export enum Key {
  ArrowDown = 'ArrowDown',
  ArrowUp = 'ArrowUp',
  Enter = 'Enter',
  Escape = 'Escape',
  // Alt = 'Alt',
  Space = ' ',
  L = 'KeyL',
  D = 'KeyD',
}

export const isKey = (event: KeyboardEvent, key: Key) => event.key === key || event.code === key;

export const useOnKeyDown = (key: Key, handler: (event: KeyboardEvent) => void) => {
  const handlerRef = useVariable(handler);

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (isKey(event, key)) {
        handlerRef.current(event);
      }
    };

    window.addEventListener('keydown', onKeyDown, { capture: true });

    return () => {
      window.removeEventListener('keydown', onKeyDown, { capture: true });
    };
  }, [handlerRef, key]);
};
