'use client';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';

import { useCookieConsent } from 'hooks/useCookieConsent';

import type { Toast as Item } from './Toast.types';
import { exposeToastToGlobal, getToastsInQueue } from './globalExpose';

const Toast = dynamic(() => import('./Toast'), { ssr: false });

export const ToastContainer = () => {
  const [{ consented }] = useCookieConsent();
  const [toasts, setToasts] = useState<Item[]>(getToastsInQueue);

  useEffect(() => {
    exposeToastToGlobal(setToasts);
  }, []);

  const toast = toasts.at(0);

  if (!consented || !toast) {
    return null;
  }

  return (
    <Toast
      key={toast.id}
      toast={toast}
      onClose={() => {
        setToasts(([_first, ...rest]) => rest);
      }}
    />
  );
};
