import classNames from 'classnames';
import { forwardRef } from 'react';

import { LocalizedMessage } from 'components/i18n/LocalizedMessage/LocalizedMessage';
import Link from 'components/ui/Link';

import { LoginMessage } from 'constants/i18n.messages';

import { useLoginUrl } from 'utils/navigation';

import CustomerAvatar from '../ProfileNavigation/CustomerAvatar';
import styles from './LoginLink.module.css';

export const LoginLink = forwardRef<HTMLAnchorElement, { className?: string }>(function LoginButton(
  { className },
  ref,
) {
  const loginUrl = useLoginUrl();

  return (
    <Link
      className={classNames(className, styles.button)}
      data-testid="login-link"
      data-analytics-id="login-link"
      aria-haspopup="dialog"
      ref={ref}
      href={loginUrl}
      forceInternal
      scroll={false}
    >
      <CustomerAvatar outline />
      <span className={styles.desktop}>
        <LocalizedMessage id={LoginMessage.LOGIN} />
      </span>
      <span className={styles.mobile}>
        <LocalizedMessage id={LoginMessage.LOGIN_SHORT_VARIANT} />
      </span>
    </Link>
  );
});
