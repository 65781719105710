import { gql, useMutation } from '@apollo/client';

import { ClaimSaleMutation, ClaimSaleMutationVariables } from 'backend/types.codegen';

const CLAIM_SALE = gql`
  mutation claimSale($saleId: String!) {
    claimSale(saleId: $saleId)
  }
`;

export const useClaimSession = () =>
  useMutation<ClaimSaleMutation, ClaimSaleMutationVariables>(CLAIM_SALE, {
    errorPolicy: 'all',
  });
