'use client';
import { pushConsentSettingsEvent } from 'analytics/userActions/pushConsentSettingsEvent';
import { Page } from 'build/types';
import { LOCALE } from 'constants/i18n';
import { useCookieConsent } from 'hooks/useCookieConsent';
import { useOnMount } from 'hooks/useOnMount';
import { usePageViewer } from 'hooks/usePageViewer';

type Props = { page: Page; locale: LOCALE };

const RouteListeners = ({ page, locale }: Props) => {
  const [{ levels }] = useCookieConsent();

  usePageViewer({ locale, page });

  useOnMount(() => pushConsentSettingsEvent(levels), { waitUntilHydrated: true });

  return null;
};

export default RouteListeners;
