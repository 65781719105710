import { usePathname } from 'next/navigation';
import { useMemo } from 'react';

import { FormPages } from 'build/pages/types';

import { usePageSlugs } from './usePages';

export const useCurrentFormPage = () => {
  const pathname = usePathname();
  const pageSlugs = usePageSlugs();

  const formPages = useMemo(
    () => Object.values(FormPages).map((page) => ({ page, pathname: pageSlugs[page] })),
    [pageSlugs],
  );

  return formPages.find((page) => page.pathname === pathname)?.page;
};
