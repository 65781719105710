import classNames from 'classnames';

import styles from './InitialsAvatar.module.css';

export type AccountColor = 'blue' | 'yellow';

type Props = {
  text: string;
  color: AccountColor;
  initialsCount?: number;
};

const classNameMap: Record<AccountColor, string> = {
  blue: styles.blue,
  yellow: styles.yellow,
};

export const InitialsAvatar = ({ text, color, initialsCount = 2 }: Props) => {
  const ignoreWordsRegexp = new RegExp(/\b(oyj|oy)/gi);
  const initialsRegexp = new RegExp(/\b[a-zA-Z]/g);

  const parsed = text.replace(ignoreWordsRegexp, '');

  const initials = parsed.match(initialsRegexp)?.join('').substring(0, initialsCount) ?? '';

  return (
    <span
      data-testid="initials-avatar"
      data-text="16 semibold"
      className={classNames(styles.bg, classNameMap[color])}
    >
      {' '}
      {initials?.toUpperCase()}
    </span>
  );
};
