import { getStoredState, setStoredState } from 'hooks/useStoredState';

import { StorageKey } from 'types/stateStore';

import { isSSR } from './isSSR';
import { generateRandomId } from './misc';

export const getSessionId = () => {
  if (isSSR()) {
    return generateRandomId();
  }

  const storedSessionId = getStoredState(StorageKey.SessionId);
  const sessionId = storedSessionId || generateRandomId();
  if (storedSessionId !== sessionId) {
    setStoredState(StorageKey.SessionId, sessionId);
  }
  return sessionId;
};
