import { forwardRef } from 'react';

import { CmPasswordProps, createForm } from '@vrfi/web-components';

import form from '@vrfi/web-components/css/form.module.css';
import typography from '@vrfi/web-components/css/typography.module.css';
import utils from '@vrfi/web-components/css/utils.module.css';

import { LocalizedMessage } from 'components/i18n/LocalizedMessage/LocalizedMessage';

import { PasswordMessage } from 'constants/i18n.messages';

const FormComponents = createForm({
  form,
  typography,
  utils,
});

export const { Checkbox, Fieldset, Quantity, Radio, Select, Input, Textarea, Toggle } =
  FormComponents;

export const Password = forwardRef<
  HTMLInputElement,
  Omit<CmPasswordProps, 'passwordShow' | 'passwordHide'>
>(function Password(props, ref) {
  return (
    <FormComponents.Password
      {...props}
      passwordShow={<LocalizedMessage id={PasswordMessage.SHOW} />}
      passwordHide={<LocalizedMessage id={PasswordMessage.HIDE} />}
      ref={ref}
    />
  );
});
