/** @knipignore */
export const secondsToMs = (value: number) => value * 1000;

/** @knipignore */
export const minutesToMs = (value: number) => value * 60 * 1000;

/** @knipignore */
export const hoursToMs = (value: number) => value * 60 * 60 * 1000;

/** @knipignore */
export const daysToMs = (value: number) => value * 24 * 60 * 60 * 1000;
