import classNames from 'classnames';

import { pushChangeLanguageEvent } from 'analytics/userActions/pushChangeLanguageEvent';

import Link from 'components/ui/Link';

import { LOCALE } from 'constants/i18n';

import { NextLocation } from 'utils/navigation';
import { createRelativeUrlTo } from 'utils/route';

import styles from './Language.module.css';

interface Props {
  name: LOCALE;
  url: string;
  isCurrent: boolean;
  label: string;
  location: NextLocation;
  onClick?: () => void;
}

export const Language = ({
  name,
  url,
  isCurrent,
  label,
  location,
  onClick: onClickProp,
}: Props) => {
  const onClick = () => {
    if (!isCurrent) {
      pushChangeLanguageEvent(name);
    }
  };

  const ariaLabel = `${name.toUpperCase()}: ${label}`;

  return onClickProp ? (
    <button
      className={classNames(styles.link, { [styles.current]: isCurrent })}
      aria-label={ariaLabel}
      lang={name}
      onClick={onClickProp}
    >
      {name.toUpperCase()}
    </button>
  ) : (
    <Link
      className={classNames(styles.link, { [styles.current]: isCurrent })}
      data-testid={`language-link-${name}`}
      href={createRelativeUrlTo({ ...location, pathname: url })}
      role="link"
      aria-label={ariaLabel}
      lang={name}
      disableLocalization
      onClick={onClick}
      aria-current={isCurrent}
    >
      {name.toUpperCase()}
    </Link>
  );
};
