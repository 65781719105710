import { usePathname } from 'next/navigation';
import { useMemo } from 'react';

import { ProfilePages } from 'build/pages/types';

import { useSiteData } from 'contexts/siteData';

import { usePageSlugs } from 'hooks/usePages';

import { entries } from 'utils/object';

const PROFILE_PAGES_TO_IGNORE: ProfilePages[] = [
  ProfilePages.MY_JOURNEYS_PAGE,
  ProfilePages.PROFILE_PAGE,
  ProfilePages.MY_PURCHASE_HISTORY_PAGE,
  ProfilePages.CHANGE_YOUR_PASSWORD_PAGE,
];

export const useIsOnTripRelatedPage = () => {
  const pathname = usePathname();
  const pageSlugs = usePageSlugs();
  const {
    purchaseFlowSteps: { journeyChangeFlowStepsSteps, profilePurchaseFlowSteps },
  } = useSiteData();

  const tripRelatedPaths = useMemo(() => {
    const profilePages: string[] = Object.values(ProfilePages).filter(
      (page) => !PROFILE_PAGES_TO_IGNORE.includes(page),
    );

    return [
      ...Object.values(journeyChangeFlowStepsSteps).map(({ path }) => path),
      ...Object.values(profilePurchaseFlowSteps).map(({ path }) => path),
      ...entries(pageSlugs).flatMap(([routeKey, path]) =>
        profilePages.includes(routeKey) ? path : [],
      ),
    ];
  }, [journeyChangeFlowStepsSteps, profilePurchaseFlowSteps, pageSlugs]);

  return tripRelatedPaths.includes(pathname);
};
