import { ComponentProps, ReactNode } from 'react';

import { Button } from '@vrfi/web-components';

import { ButtonLoading } from '../Loading';

type Props = {
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  children: ReactNode;
} & ComponentProps<typeof Button>;

export const ActionButton = ({ loading, disabled, onClick, children, ...props }: Props) => {
  const disable = loading || disabled;
  return (
    <Button {...props} aria-disabled={disabled} onClick={disable ? undefined : onClick}>
      <ButtonLoading loading={loading}>{children}</ButtonLoading>
    </Button>
  );
};
