import { useCallback, useState } from 'react';

export const useThrowError = () => {
  const [_, setError] = useState();

  return useCallback(
    (error: unknown) => {
      setError(() => {
        throw error;
      });
    },
    [setError],
  );
};
