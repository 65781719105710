import { FormPages } from 'build/pages/types';

import { useLocation } from 'utils/navigation';

import { useIsTicketCancellationFlowPage } from './useIsTicketCancellationFlowPage';
import { usePageSlugs } from './usePages/usePages';

export const useIsFormsTicketCancellationFlowPage = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const isTicketCancellationFlow = useIsTicketCancellationFlowPage();
  const slugs = usePageSlugs();
  const ticketCancellationFormSlug = slugs[FormPages.TICKET_CANCELLATION_FORM];
  const isTicketCancellationFormPage = location.pathname === ticketCancellationFormSlug;
  return (
    (params.get('source') === 'forms' && isTicketCancellationFlow) || isTicketCancellationFormPage
  );
};
