import { useEffect, useRef } from 'react';

import { useReleaseSale } from 'backend/hooks/useReleaseSale';

import { StepKey } from 'components/structural/PurchaseFlowSteps/types';

import { useCurrentPurchaseFlowStep } from 'hooks/purchaseFlow/useCurrentPurchaseFlowStep';
import { useIsInPurchaseFlow } from 'hooks/purchaseFlow/useIsInPurchaseFlow';

import {
  JourneyChangeFlowStepsStepEnum,
  MultiTicketPurchaseFlowStepEnum,
  ProfilePurchaseFlowStepEnum,
  PurchaseFlowStepEnum,
} from 'types/purchaseFlow';

import { useSalesSessionUrlParam } from './useSalesSessionUrlParam';

const stepsWhichReleaseSale: StepKey[] = [
  PurchaseFlowStepEnum.OutboundSearchResults,
  JourneyChangeFlowStepsStepEnum.JourneySearchPage,
  ProfilePurchaseFlowStepEnum.PreOrderBreakfastPage,
  MultiTicketPurchaseFlowStepEnum.OffersPage,
];

export const useReleaseSalesSessionWhenNeeded = () => {
  const [releaseSale] = useReleaseSale();
  const isInPurchaseFlow = useIsInPurchaseFlow();
  const currentStep = useCurrentPurchaseFlowStep();
  const { saleId } = useSalesSessionUrlParam();
  const saleIdRef = useRef<string | undefined>(undefined);

  const shouldReleaseSale =
    !saleId && (!isInPurchaseFlow || (currentStep && stepsWhichReleaseSale.includes(currentStep)));

  useEffect(() => {
    const prevSaleId = saleIdRef.current;
    if (!shouldReleaseSale || !prevSaleId) {
      return;
    }

    releaseSale({ variables: { saleId: prevSaleId } });
  }, [releaseSale, shouldReleaseSale]);

  useEffect(() => {
    saleIdRef.current = saleId;
  }, [saleId]);
};
