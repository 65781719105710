import { ReactNode, useCallback, useEffect, useRef } from 'react';

import { focusOnOpenProp } from 'utils/focus';
import { Key, isKey } from 'utils/keyboard';

import { FocusOn } from '../FocusOn/FocusOn';

/**
 * This component keeps focus inside the dialog when it's open. Also, it focuses element when it's opened in this order:
 * 1. Element which has `data-focus-on-open` prop defined. Example: `<input {...focusOnOpen}/>`
 * 2. First heading inside the dialog: h1-h6
 * 3. Whole dialog element which has role="dialog"
 */
export const ModalFocusHandler = ({
  children,
  disabled,
  onClose,
  scrollLock,
}: {
  children: ReactNode;
  disabled?: boolean;
  onClose?: (event: KeyboardEvent) => void;
  scrollLock?: boolean;
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const current = modalRef?.current;
    if (!onClose || !current) {
      return;
    }
    const onKeyDown = (event: KeyboardEvent) => {
      if (isKey(event, Key.Escape)) {
        event.stopPropagation();

        onClose(event);
      }
    };

    current.addEventListener('keydown', onKeyDown);

    return () => {
      current.removeEventListener('keydown', onKeyDown);
    };
  }, [onClose]);

  const onFocusLockActivate = useCallback(() => {
    if (modalRef.current) {
      const focusableElement = modalRef.current.querySelector<HTMLElement>(`[${focusOnOpenProp}]`);
      const firstHeading = modalRef.current.querySelector<HTMLElement>('h1,h2,h3,h4,h5,h6');
      const dialog = modalRef.current.querySelector<HTMLElement>('[role="dialog"]');

      const elementToFocus = focusableElement || firstHeading || dialog;

      if (elementToFocus) {
        const tabIndexBefore = elementToFocus.tabIndex;
        elementToFocus.tabIndex = -1;
        elementToFocus.focus();
        elementToFocus.tabIndex = tabIndexBefore;
      }
    }
  }, []);

  return (
    <FocusOn
      enabled={!disabled}
      returnFocus
      onActivation={onFocusLockActivate}
      autoFocus={false}
      scrollLock={scrollLock}
    >
      <div ref={modalRef}>{children}</div>
    </FocusOn>
  );
};
