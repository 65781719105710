import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { LOCALE } from 'constants/i18n';

import { useIsInPurchaseFlow } from 'hooks/purchaseFlow/useIsInPurchaseFlow';
import { useSelectedRole } from 'hooks/useSelectedRole';
import { useStoredState } from 'hooks/useStoredState';

import { StorageKey } from 'types/stateStore';

import { useLocation } from 'utils/navigation';

import { emptyArray } from 'utils/array';
import { ExternalNotification } from '../types';
import { Notification, isUserTypeScope, useGetNotifications } from './useGetNotifications';

const mapNotifications =
  (locale: LOCALE) =>
  (notification: Notification): ExternalNotification => ({
    id: `${notification.id}:${notification.revision ?? 0}`,
    title: notification.title[locale],
    body: notification.body[locale],
    type: notification.type,
  });

const isInScope = (notification: Notification, inPurchaseFlow: boolean) =>
  notification.scope.includes('full') ||
  (inPurchaseFlow && notification.scope.includes('purchaseFunnel')) ||
  (!inPurchaseFlow && notification.scope.includes('contentPages'));

const isPathnameMatch = (notification: Notification, pathname: string) =>
  notification.webUrls?.includes(pathname);

export const useExternalNotifications = () => {
  const { data } = useGetNotifications();
  const { pathname } = useLocation();
  const inPurchaseFlow = useIsInPurchaseFlow();
  const { locale } = useIntl();
  const { isCompanyUser: isCorporate } = useSelectedRole();

  const filterNotificationsByLocation = useCallback(
    (notification: Notification) =>
      isInScope(notification, inPurchaseFlow) || isPathnameMatch(notification, pathname),
    [inPurchaseFlow, pathname],
  );

  const filterNotificationsByUserType = useCallback(
    (notification: Notification) => {
      const userTypes = {
        isCorporate,
      };
      return notification.scope.some((scope) => isUserTypeScope(scope) && userTypes[scope]);
    },
    [isCorporate],
  );

  const [readNotifications, setReadNotifications] = useStoredState(StorageKey.ReadNotifications);

  const externalNotifications = useMemo(() => {
    const allNotices =
      data
        ?.filter(
          (notification) =>
            filterNotificationsByLocation(notification) ||
            filterNotificationsByUserType(notification),
        )
        .map(mapNotifications(locale))
        .filter(({ id }) => !readNotifications?.includes(id)) ?? emptyArray;

    const alerts = allNotices.filter((notice) => notice.type === 'Alert');
    if (alerts.length > 0) {
      return alerts;
    }

    const majorNotices = allNotices.filter((notice) => notice.type === 'MajorNotice');
    if (majorNotices.length > 0) {
      return majorNotices;
    }

    return allNotices;
  }, [
    data,
    filterNotificationsByLocation,
    filterNotificationsByUserType,
    locale,
    readNotifications,
  ]);

  const setExternalNotificationRead = useCallback(
    (id: string) => {
      setReadNotifications(
        (prev) => (prev?.includes(id) ? prev : [...(prev ?? []), id].slice(0, 20)), // limit amount of read notification IDs to 20, so it does not grow indefinitely
      );
    },
    [setReadNotifications],
  );

  return {
    externalNotifications,
    setExternalNotificationRead,
  };
};
