import { useEffect, useRef } from 'react';

import analytics from 'analytics/analytics';

import { PageProps } from 'build/types';

import { useSiteData } from 'contexts/siteData';

import { UserType } from 'types/analytics';

import { useLocation } from 'utils/navigation';

import { useIsMounted } from 'contexts/isMounted';
import { useIdentity } from './useIdentity';
import { useSelectedRole } from './useSelectedRole';

export const usePageViewer = (pageProps: Pick<PageProps, 'page' | 'locale'>) => {
  const mounted = useIsMounted();

  const { pathname } = useLocation();
  const lastPathName = useRef<string | null>(null);

  const [identity] = useIdentity();
  const {
    metadata: { webFrontendVersion },
  } = useSiteData();

  const { isCompanyUser, isTravelAgentUser } = useSelectedRole();

  const customerId = identity?.customerId;
  const userType: UserType = isTravelAgentUser
    ? 'TRAVEL_AGENCY_CLERK'
    : isCompanyUser
    ? 'CORPORATE'
    : identity?.isEmployee
    ? 'EMPLOYEE'
    : 'CONSUMER';

  useEffect(() => {
    // wait for mount so the local storage has been read
    if (!mounted) {
      return;
    }

    if (pathname !== lastPathName.current) {
      analytics.loadPage({
        pageProps,
        webFrontendVersion,
        customerId,
        userType,
      });
      lastPathName.current = pathname;
    }
  }, [pageProps, webFrontendVersion, userType, mounted, pathname, customerId]);
};
