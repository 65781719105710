import classNames from 'classnames';
import { useIntl } from 'react-intl';

import { ColorEnum } from '@vrfi/web-components';

import { ProfilePages } from 'build/pages/types';

import { Button } from '@vrfi/web-components';
import { Box, Text } from 'components/cssModules';
import LocalizedMessage from 'components/i18n/LocalizedMessage';
import { ActionButton } from 'components/ui/ActionButton';
import { CancelIcon } from 'components/ui/Icons/index';

import { GenericMessage, LoginMessage, ProfileMessage } from 'constants/i18n.messages';

import { useIdentity } from 'hooks/useIdentity';
import { useIsConsumerUser } from 'hooks/useIsConsumerUser';
import { useIsEmployeeUser } from 'hooks/useIsEmployeeUser';
import { usePageSlug, usePageTitle } from 'hooks/usePages/usePages';

import { margin, padding } from 'styles/compLib';

import profileStyles from '../ProfileNavigation.module.css';
import { useDoLogout } from '../useDoLogout';
import { ProfileNavigationLink } from './ProfileNavigationLink';
import styles from './SingleProfileContent.module.css';

export const SingleProfileContent = ({ onClose }: { onClose: () => void }) => {
  const intl = useIntl();
  const isEmployee = useIsEmployeeUser();
  const [identity] = useIdentity();
  const isConsumerUser = useIsConsumerUser();

  const { doLogout, loading: isLogoutLoading } = useDoLogout(onClose);

  const { firstName, lastName } = identity ?? {};

  const profilePageTitle = usePageTitle(ProfilePages.PROFILE_PAGE);
  const journeysPageTitle = usePageTitle(ProfilePages.MY_JOURNEYS_PAGE);
  const profileLinkTarget = usePageSlug(ProfilePages.PROFILE_PAGE);
  const journeysLinkTarget = usePageSlug(ProfilePages.MY_JOURNEYS_PAGE);

  return (
    <Box as="section" spacing="s00Column" variant="s16Box">
      <header className={classNames(styles.header, { [styles.employee]: isEmployee })}>
        <Button
          aria-label={intl.formatMessage({ id: GenericMessage.CLOSE })}
          variant="s02Circle"
          onClick={onClose}
          data-testid="profile-navigation-close-button"
        >
          <CancelIcon color={ColorEnum.green10} />
        </Button>

        <div className={profileStyles.userInfo}>
          <Text
            paragraph={16}
            semibold
            className={profileStyles.userName}
            data-testid="profile-navigation-name"
          >
            {firstName ? (
              <>
                {firstName} {lastName}
              </>
            ) : (
              <LocalizedMessage
                id={ProfileMessage.PROFILE_NAVIGATION_BUTTON_FIRST_NAME_PLACEHOLDER}
              />
            )}
          </Text>

          {isEmployee && (
            <Text
              paragraph={16}
              varGrey17
              className={`${padding.s48Right} ${margin.s04Top}`}
              data-testid="profile-navigation-account-name"
            >
              <LocalizedMessage id={ProfileMessage.VR_USER} />
            </Text>
          )}
        </div>
      </header>

      <Box as="nav" className={margin.s16Block} spacing="s16Column">
        <ProfileNavigationLink
          title={journeysPageTitle}
          target={journeysLinkTarget}
          onClick={onClose}
        />
        {isConsumerUser && (
          <ProfileNavigationLink
            title={profilePageTitle}
            target={profileLinkTarget}
            onClick={onClose}
          />
        )}
      </Box>
      <ActionButton
        loading={isLogoutLoading}
        stretch
        grey30
        data-testid="logout-link"
        onClick={doLogout}
      >
        <LocalizedMessage id={LoginMessage.LOGOUT} />
      </ActionButton>
    </Box>
  );
};
