import { Block, Document, Inline, Text, helpers } from '@contentful/rich-text-types';

export const getDocumentLength = (document: Document) => {
  const count = (block: Block | Inline | Text): number => {
    if (block.nodeType === 'text') {
      return block.value.length;
    }
    if (helpers.isBlock(block)) {
      return block.content.reduce((acc, innerBlock) => acc + count(innerBlock), 0);
    }
    return block.content.reduce((acc, innerBlock) => acc + count(innerBlock), 0);
  };

  return count(document);
};
