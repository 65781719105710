import { ColorEnum } from '@vrfi/web-components';

import { Text } from 'components/cssModules';
import { ChevronRightIcon } from 'components/ui/Icons/index';
import Link from 'components/ui/Link';

import styles from './ProfileNavigationLink.module.css';

type Props = {
  title: string | undefined;
  target: string;
  onClick: (event: React.MouseEvent) => void;
};

export const ProfileNavigationLink = ({ title, target, onClick }: Props) => (
  <Link onClick={onClick} href={target} className={styles.wrapper}>
    <Text paragraph={16} semibold blue10>
      {title}
    </Text>

    <ChevronRightIcon size={2} color={ColorEnum.blue10} />
  </Link>
);
