import { Notification } from 'components/cssModules/Notification';

import { ColorEnum } from '@vrfi/web-components';

import { ContentWrapper } from 'components/ui/ContentWrapper';
import styles from '../Notification/Notification.module.css';
import { useExpirationNotification } from './useExpirationNotification';

export const ExpirationNotification = () => {
  const { show, title, body, onClose } = useExpirationNotification();

  if (!show) {
    return null;
  }

  return (
    <ContentWrapper className={styles.notificationWrapper} backgroundColor={ColorEnum.blue20}>
      <Notification title={title} showClose onClose={onClose} role="alert">
        {body}
      </Notification>
    </ContentWrapper>
  );
};
