import { ProfilePages } from 'build/pages/types';

import { useLocation } from 'utils/navigation';

import { usePageSlugs } from './usePages/usePages';

const ticketCancellationFlowPages = [
  ProfilePages.CANCEL_JOURNEY_PAGE,
  ProfilePages.SELECT_JOURNEYS_TO_CANCEL_PAGE,
  ProfilePages.SELECT_VEHICLES_TO_CANCEL_PAGE,
];

export const useIsTicketCancellationFlowPage = () => {
  const location = useLocation();
  const slugs = usePageSlugs();
  const ticketCancellationFlowSlugs = ticketCancellationFlowPages.map((page) => slugs[page]);
  return ticketCancellationFlowSlugs.includes(location.pathname);
};
