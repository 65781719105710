import LocalizedMessage from 'components/i18n/LocalizedMessage';
import CustomerAvatar from 'components/ui/Header/ProfileNavigation/CustomerAvatar';
import { InitialsAvatar } from 'components/ui/InitialsAvatar/InitialsAvatar';
import { Loading } from 'components/ui/Loading/Loading';

import { ProfileMessage, ProfileSelectionMessage } from 'constants/i18n.messages';

import { useIdentity } from 'hooks/useIdentity';
import { useSelectedRole } from 'hooks/useSelectedRole';

import { truncate } from 'utils/string';

import styles from '../ProfileButton.module.css';

export const MultiProfileContent = () => {
  const [identity] = useIdentity();
  const firstName = identity?.firstName;
  const lastName = identity?.lastName;

  const { isConsumerUser, isCompanyUser, isTravelAgentUser, selectedCompanyName, loading } =
    useSelectedRole();

  const userLabel = isCompanyUser ? (
    selectedCompanyName
  ) : identity?.companyRoles && identity?.companyRoles?.length > 0 ? (
    <LocalizedMessage
      id={
        isTravelAgentUser
          ? ProfileSelectionMessage.TRAVEL_AGENT_PROFILE
          : ProfileSelectionMessage.CUSTOMER_PROFILE
      }
    />
  ) : undefined;

  if (!identity?.isCiamUser) return null;

  return loading ? (
    <Loading center animation="bars" size={2} color="var(--color-grey17)" />
  ) : (
    <>
      <span id="profile-menu-nav-icon">
        {(isCompanyUser || isTravelAgentUser) &&
          (!!selectedCompanyName || firstName || lastName) && (
            <InitialsAvatar
              text={selectedCompanyName ?? [firstName, lastName].join(' ')}
              color={isTravelAgentUser ? 'yellow' : 'blue'}
            />
          )}
        {isConsumerUser && <CustomerAvatar />}
      </span>

      <div className={styles.contentContainer} id="profile-button-content">
        <span className={styles.firstName} data-testid="user-first-name">
          {firstName ? (
            truncate(firstName, 16)
          ) : (
            <LocalizedMessage
              id={ProfileMessage.PROFILE_NAVIGATION_BUTTON_FIRST_NAME_PLACEHOLDER}
            />
          )}
        </span>

        {userLabel && (
          <div data-testid="user-label" className={styles.userLabel}>
            {userLabel}
          </div>
        )}
      </div>
    </>
  );
};
