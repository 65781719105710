import pushToDataLayer from 'analytics/pushToDataLayer';
import { resolveUserRole } from 'analytics/utils';

import { UserIdentity } from 'contexts/identity';

import { createRelativeUrlTo } from 'utils/route';

type CiamProfileProps =
  | {
      userRole: 'CONSUMER';
      userId?: string;
    }
  | {
      userRole: 'CORPORATE' | 'TRAVEL_AGENCY_CLERK';
      userId?: string;
      companyId?: string;
    };

const getCiamProfileProps = (selectedRole: string, identity?: UserIdentity): CiamProfileProps => {
  const isConsumer = selectedRole === 'customer';

  if (!isConsumer && identity?.companyRoles) {
    for (const companyRole of identity.companyRoles) {
      const match = companyRole.roles.find(({ id }) => id === selectedRole);
      if (match) {
        return {
          userId: identity?.customerId,
          companyId: match.id.split('-').at(0),
          userRole: resolveUserRole(match.role),
        };
      }
    }
  }

  return {
    userRole: 'CONSUMER',
    userId: identity?.customerId,
  };
};

export const pushProfileSelectEvent = (selectedRole: string, identity?: UserIdentity) => {
  pushToDataLayer({
    event: 'userAction',
    pagePath: createRelativeUrlTo(window.location),
    timestamp: Date.now(),
    eventCategory: 'user account',
    eventAction: 'profileSelect',
    eventLabel: null,
    eventNonInteraction: false,
    ...getCiamProfileProps(selectedRole, identity),
  });
};
