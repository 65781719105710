import { RefObject, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { ProfileMessage } from 'constants/i18n.messages';

import { useIsToSlice } from 'hooks/mediaQuery';
import { useIdentity } from 'hooks/useIdentity';

import { Collapse } from '@vrfi/web-components';
import { ModalFocusHandler } from '../../ModalFocusHandler';
import { MultiProfileContent } from './MultiProfileContent/MultiProfileContent';
import styles from './ProfileNavigation.module.css';
import { SingleProfileContent } from './SingleProfileContent/SingleProfileContent';

interface Props {
  open: boolean;
  onClose: () => void;
  positioningElement: RefObject<HTMLElement>;
}

export function ProfileNavigation({ open, onClose, positioningElement }: Props) {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const intl = useIntl();
  const [identity] = useIdentity();
  const isSmallScreen = useIsToSlice('SM');

  // used to track the position of the profile toggle.
  // the nav menu is placed on top of the toggle in specific place
  const [offset, setOffset] = useState<number | undefined>(undefined);

  useEffect(() => {
    function handleUserKeyPress(event: KeyboardEvent) {
      if (event.defaultPrevented) return;
      if (event.key === 'Escape' && open) {
        onClose();
        event.preventDefault();
      }
    }

    function handleClickOutside(event: MouseEvent) {
      if (
        open &&
        !event.defaultPrevented &&
        wrapperRef.current &&
        event.target &&
        !wrapperRef.current.contains(event.target as HTMLElement)
      ) {
        onClose();
        event.preventDefault();
      }
    }

    function setStyleOffset() {
      setOffset(positioningElement.current?.getBoundingClientRect().right);
    }

    if (open) {
      setStyleOffset();
      window.addEventListener('click', handleClickOutside, true);
      window.addEventListener('keydown', handleUserKeyPress, true);
      window.addEventListener('resize', setStyleOffset);

      return () => {
        window.removeEventListener('click', handleClickOutside, true);
        window.removeEventListener('keydown', handleUserKeyPress, true);
        window.removeEventListener('resize', setStyleOffset);
      };
    }

    return;
  }, [onClose, open, positioningElement]);

  return (
    <ModalFocusHandler disabled={!open} scrollLock={isSmallScreen}>
      <div
        className={styles.animateWrapper}
        style={!isSmallScreen ? { left: offset ? `calc(${offset}px - 20rem` : '50%' } : undefined}
        aria-modal
        aria-hidden={!open}
        role="dialog"
        aria-label={intl.formatMessage({
          id: ProfileMessage.PROFILE_NAVIGATION_ACCESSIBILITY_TITLE,
        })}
        data-testid="profile-navigation-modal"
      >
        <Collapse open={open}>
          <div className={styles.innerWrapper} color="white" ref={wrapperRef} tabIndex={-1}>
            {identity?.isCiamUser ? (
              <MultiProfileContent onClose={onClose} />
            ) : (
              <SingleProfileContent onClose={onClose} />
            )}
          </div>
        </Collapse>
      </div>
    </ModalFocusHandler>
  );
}
